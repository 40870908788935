<!-- eslint-disable -->
<script setup>
import reportFamillePDF from "@/components/ToPDF/reportFamille/IndexPdf.vue";
import SynthesePDF from "@/components/ToPDF/reportFamille/synthesePdf.vue";
import journaliesVAT from "@/components/ToPDF/reportFamille/journaliesVAT.vue";
import journaliesPayements from "@/components/ToPDF/reportFamille/journaliesPayements.vue";
import SyntheseExcel from "@/components/ToPDF/reportFamille/syntheseExcel.vue";
import tvaPDF from "@/components/ToPDF/Tva/IndexPdf.vue";
import remisePDF from "@/components/ToPDF/Remise/IndexPdf.vue";
import CardSearch from "@/components/CardSearch.vue";
import { ref } from 'vue';
const expandedReportRows = ref([]);
</script>
<!-- eslint-disable -->
<template>
  <!-- <ButtonDates /> -->
  <CardSearch v-if="currentSite" @search="callFunctions" :operation="false" :etat="false" :caisse="true"
    :vendeur="true" />

  <div v-if="dataReportFamille.length > 0" class="row">
    <div class="col-md-7 export-btn grid-2 mb-3">
      <span type="button" class="col-btn ">
        <SynthesePDF :exportTo="exportTo" :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
      </span>
      <span type="button" class="col-btn ">
        <SyntheseExcel :exportTo="exportTo" :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-7 grid-margin stretch-card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Famille
                <div v-if="dataReportFamille.length > 0" class="float-end d-flex">
                  <reportFamillePDF :exportTo="exportTo.famille" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </h3>
            </div>
            <div v-if="dataReportFamille.length > 0" class="card-body">
              <div v-if="dataReportFamille[0]?.length > 0" class="table-re²sponsive tableClasss">
                <DataTable showGridlines v-model:expandedRows="expandedReportRows" :value="dataReportFamille[0]"
                  dataKey="_id">
                  <Column expander style="width: 5rem" />
                  <Column field="_id" header="Famille" footer="Total" sortable></Column>
                  <Column field="QuantityFamille" header="Qte"
                    :footer="dataReportFamille[1].TotalQuantityFamille.toFixed(2)" sortable>
                  </Column>
                  <Column field="QteRatioFamille" header="Ratio"
                    :footer="`${dataReportFamille[1].ratioTotalQte.toFixed(2)} %`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.QteRatioFamille.toFixed(2) }}
                    </template>
                  </Column>
                  <Column field="PrixTotalNetFamille" header="CA"
                    :footer="`${dataReportFamille[1].totalPrixTotalNetFamille.toFixed(2)} €`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.PrixTotalNetFamille.toFixed(2) }} €
                    </template>
                  </Column>
                  <Column field="PrixRatioFamille" header="Ratio"
                    :footer="`${dataReportFamille[1].ratioTotalPrix.toFixed(2)} %`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.PrixRatioFamille.toFixed(2) }}
                    </template>
                  </Column>
                  <template #expansion="slotProps">
                    <div class="p-3">
                      <DataTable :value="slotProps.data.LibFamilles">
                        <Column field="libelle" header="libelle" sortable></Column>
                        <Column field="QuantityLibelle" header="Qte" sortable></Column>
                        <Column field="QuantityLibelle" header="Ratoi" sortable>
                          <template #body="slotProps">
                            {{ Division(slotProps.data.QuantityLibelle * 100, slotProps.data.QuantityFamille) }} %
                          </template>
                        </Column>
                        <Column field="PrixTotalNetLibelle" header="CA" sortable>
                          <template #body="slotProps">
                            {{ slotProps.data.PrixTotalNetLibelle.toFixed(2) }} €
                          </template>
                        </Column>
                        <Column field="PrixTotalNetLibelle" header="Ratio" sortable>
                          <template #body="slotProps">
                            {{ Division(slotProps.data.PrixTotalNetLibelle * 100,
                              slotProps.data.PrixTotalNetFamille) }}
                            %
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </template>
                </DataTable>
              </div>
            </div>
            <div v-else class="card-body">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Chart graphique
              </h3>
            </div>
            <div class="body-card">
              <DoughnutChart :chartData="chartjs.chartDataFamille" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataReportTVA[0].length > 0" class="row">
        <div class="col-md-7 grid-margin stretch-card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                TVA
                <div v-if="dataReportTVA.length > 0" class="float-end d-flex">
                  <tvaPDF :exportTo="exportTo.tva" :dateFrom="datePicker.date.from" :dateTo="datePicker.date.to" />
                </div>
              </h3>
            </div>
            <div v-if="dataReportTVA.length > 0" class="card-body">
              <div class="table-responsive tableClasss">
                <DataTable showGridlines stripedRows :value="dataReportTVA[0]">
                  <Column field="_id.Libelle" header="libelle" :footer="`Total`" sortable></Column>
                  <Column field="TotalValeurTVA" header="TVA"
                    :footer="`${dataReportTVA[1]?.TotalValeurTVA.toFixed(2)} €`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.TotalValeurTVA.toFixed(2) }} €
                    </template>
                  </Column>
                  <Column field="TotalValeurHT" header="HT" :footer="`${dataReportTVA[1]?.TotalValeurHT.toFixed(2)} €`"
                    sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.TotalValeurHT.toFixed(2) }} €
                    </template>
                  </Column>
                  <Column field="TotalValeurTTC" header="TTC"
                    :footer="`${dataReportTVA[1]?.TotalValeurTTC.toFixed(2)} €`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.TotalValeurTTC.toFixed(2) }} €
                    </template>
                  </Column>
                </DataTable>

              </div>
            </div>
            <div v-else class="card-body">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Chart graphique
              </h3>
            </div>
            <div class="body-card">
              <DoughnutChart :chartData="chartjs.chartDataTVA" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataReportREMISE[0].length > 0" class="row">
        <div class="col-md-7 grid-margin stretch-card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Remise
                <div v-if="dataReportREMISE.length > 0" class="float-end d-flex">
                  <remisePDF :exportTo="exportTo.remise" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </h3>
            </div>
            <div v-if="dataReportREMISE.length > 0" class="card-body">
              <div class="table-responsive tableClasss">
                <DataTable showGridlines stripedRows :value="dataReportREMISE[0]">
                  <Column field="_id.Libelle" header="libelle" :footer="`Total`" sortable></Column>
                  <Column field="TotalTaux" header="Taux" :footer="`${dataReportREMISE[1]?.TotalTaux.toFixed(2)} %`"
                    sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.TotalTaux.toFixed(2) }} %
                    </template>
                  </Column>

                  <Column field="TotalValeur" header="Valeur"
                    :footer="`${dataReportREMISE[1]?.TotalValeur.toFixed(2)} €`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.TotalValeur.toFixed(2) }} €
                    </template>
                  </Column>

                </DataTable>
              </div>
            </div>
            <div v-else class="card-body">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Chart graphique
              </h3>
            </div>
            <div class="body-card">
              <DoughnutChart :chartData="chartjs.chartDataREMISE" />
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataReportReglements.length > 0" class="row">
        <div class="col-md-7 grid-margin stretch-card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">Reglements</h3>
            </div>
            <div v-if="dataReportReglements[0].length > 0" class="card-body">
              <div class="table-responsive tableClasss">

                <DataTable showGridlines stripedRows :value="dataReportReglements[0]">
                  <Column field="LibFamille" header="Groupe" :footer="`Total`" sortable></Column>
                  <Column field="count" header="Quantité" :footer="`${dataReportReglements[1]?.count}`" sortable>
                  </Column>
                  <Column field="TotalValeur" header="Montant (€)"
                    :footer="`${dataReportReglements[1]?.TotalValeur.toFixed(2)} €`" sortable>
                    <template #body="slotProps">
                      {{ slotProps.data.TotalValeur.toFixed(2) }} €
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
            <div v-else class="card-body d-flex align-items-center justify-content-center">
              <datanotfound :text="$t(`content.message.datanotfound`)" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Chart graphique
              </h3>
            </div>
            <div class="body-card">
              <DoughnutChart :chartData="chartjs.chartDataReglements" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="journalier_tvas" class="row">
        <div class=" col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                Journalier TVAs
                <div class="float-end d-flex">
                  <journaliesVAT :exportTo="journalier_tvas" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </h3>
            </div>
            <div class="card-body">
              <DataTable v-if="journalier_tvas['finaleResults']?.length > 0" showGridlines
                :value="journalier_tvas['finaleResults']">
                <Column v-for="(value, key) in journalier_tvas['finaleResults'][0]" :header="key"
                  :footer="(key != 'LibSite' && key != 'Jour') ? journalier_tvas.totalObject[key].toFixed(2) + ' €' : journalier_tvas.totalObject[key]"
                  sortable>
                  <template #body="slotProps" :key=key>
                    <span v-if="key != 'LibSite' && key != 'Jour'">
                      {{ (slotProps.data[key]) }} €
                    </span>
                    <span v-else>
                      {{ (slotProps.data[key]) }}
                    </span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>


      <div v-if="journalier_payments" class="row">
        <div class=" col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title text-left">
                journalier payments
                <div class="float-end d-flex">
                  <journaliesPayements :exportTo="journalier_payments" :dateFrom="datePicker.date.from"
                    :dateTo="datePicker.date.to" />
                </div>
              </h3>
            </div>
            <div class="card-body">
              <DataTable v-if="journalier_payments['finaleResults']?.length > 0" showGridlines
                :value="journalier_payments['finaleResults']">
                <Column v-for="(value, key) in journalier_payments['finaleResults'][0]" :key="key" :field="key"
                  :header="key"
                  :footer="(key != 'LibSite' && key != 'Jour') ? journalier_payments.totalObject[key].toFixed(2) + ' €' : journalier_payments.totalObject[key]"
                  sortable>
                  <template #body="slotProps" :key=key>
                    <span v-if="key != 'LibSite' && key != 'Jour'">
                      {{ (slotProps.data[key]) }} €
                    </span>
                    <span v-else>
                      {{ (slotProps.data[key]) }}
                    </span>
                  </template>
                </Column>

              </DataTable>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title text-left">Statistique</h3>
          </div>
          <div v-if="dataReportStatistiques.length > 0" class="card-body">
            <div class="table-responsive tableClasss">

              <DataTable showGridlines stripedRows :value="dataReportStatistiques">
                <Column field="_id.Libelle" header="Type" sortable></Column>
                <Column field="QteTotal" header="Quantité" sortable>
                </Column>
                <Column field="TotalValeur" header="Montant (€)" sortable>
                  <template #body="slotProps">
                    {{ slotProps.data.TotalValeur.toFixed(2) }} €
                  </template>
                </Column>
              </DataTable>

            </div>
          </div>
          <div v-else class="card-body d-flex align-items-center justify-content-center">
            <datanotfound :text="$t(`content.message.datanotfound`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DoughnutChart from "@/components/chartVue/ChartDoughnut.vue";
import { Division } from "@/Utils/globale.js";

export default {
  components: { DoughnutChart },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {
      console.log('range')
      this.callFunctions();
    }
  },
  data() {
    return {
      journalier_payments: [],
      journalier_tvas: [],
      // expandedReportRows: [],
      exportTo: {
        site: null,
        famille: {
          columns: [],
          data: [],
          ca: 0,
          fileName: "",
        },
        tva: {
          columns: [],
          data: [],
          ca: 0,
          fileName: "",
        },
        remise: {
          columns: [],
          data: [],
          ca: 0,
          fileName: "",
        },
        reglements: {
          columns: [],
          data: [],
          ca: 0,
          fileName: "",
        },
        statistiques: {
          columns: [],
          data: [],
          ca: 0,
          fileName: "",
        },
        statistiques_caisse: {
          columns: [],
          data: [],
          ca: 0,
          fileName: "",
        }
      },
      arr_backcolor: [
        "#FED049",
        "#68B984",
        "#6ECCAF",
        "#009EFF",
        "#2A3990",
        "#F06292",
        "#285430",
        "#3B185F",
        "#F49D1A",
        "#DC3535",
        "#EF9A53",
        "#FF6D28",
        "#FF577F",
        "#FD841F",
        "#E14D2A",
        "#9C2C77",
        "#C3F8FF",
        "#FFF6BF",
        "#25316D",
        "#5F6F94",
        "#F94892",
        "#89CFFD",
        "#277BC0",
        "#FFCB42",
        "#42032C",
        "#3FA796",
        "#FEC260",
        "#A10035",
      ],

      reports: {
        famille: [],
        tva: [],
        remise: [],
        statistiques: [],
        statistiques_caisse: [],
        reglements_by_libefamille: []
      },
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 100,
        data: [],
      },
      chartjs: {
        chartDataFamille: [],
        chartDataTVA: [],
        chartDataREMISE: [],
        chartDataReglements: [],
        chiffre_affaire_total: 0,
      },

    };
  },
  mounted() {
    this.exportTo.site = this.currentSite
    // const get = async () => {

    //   this.callFunctions()
    // };
    // if (this.isAuthenticated && this.isRole == 'manager')
    //   get();

  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions(["ReportLibFamille", "ReportTVA", "ReportREMISE", "BoardRevenuStatistiques", "getReglements", "JournalierTVA", "JournalierPayments"]),
    Division,
    async getReportJournalierTVA(from, to) {
      this.journalier_tvas = await this.JournalierTVA({
        url: "report/daily-vat",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.journalier_tvas.site = this.currentSite
    },
    async getReportJournalierPayments(from, to) {
      this.journalier_payments = await this.JournalierPayments({
        url: "report/daily-payments",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      this.journalier_payments.site = this.currentSite
    },
    async getReportFamille(from, to) {
      this.reports.famille = await this.ReportLibFamille({
        url: "report/familly",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE'], LibKey: 'Operation' },
        ]
      });
      let labels = [];
      let datasets = [];
      let self = this;
      self.chartjs.chiffre_affaire_total = 0;
      await self.reports.famille[0].map((el) => {
        labels.push(el._id);
        datasets.push(el.PrixTotalNetFamille);
        self.chartjs.chiffre_affaire_total += el.PrixTotalNetFamille;
      });
      let ca_label = this.type_ca == "MontantHT" ? "CA Brut" : "CA Net";
      this.chartjs.chartDataFamille = {
        labels: labels,
        datasets: [
          {
            label: ca_label,
            backgroundColor: this.arr_backcolor,
            data: datasets,
          },
        ],
      };
      /**arr PDF */
      this.exportTo.famille.data = [];
      this.exportTo.famille.ca = this.chartjs.chiffre_affaire_total.toFixed(2);
      this.exportTo.famille.data = this.reports.famille;
      let file_vendeur = "";

      this.exportTo.famille.fileName =
        "RÉSULTAT ARTICLE" + file_vendeur + "_" + from + "_" + to;
      if (this.reports.famille.length > 0) {
        this.exportTo.famille.columns = []
        this.exportTo.famille.columns.push("Famille");
        this.exportTo.famille.columns.push("Quantité");
        this.exportTo.famille.columns.push("Ratio");
        this.exportTo.famille.columns.push("CA");
        this.exportTo.famille.columns.push("Ratio");
      }
    },
    async getReportTVA(from, to) {
      this.reports.tva = await this.ReportTVA({
        url: "report/tva",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      let labels = [];
      let datasets = [];
      let self = this;
      //self.chartjs.chiffre_affaire_total = 0
      if (self.reports.tva) {
        await self.reports.tva.map((el) => {
          labels.push(el._id.Libelle);
          datasets.push(el.TotalValeurTTC);
          //self.chartjs.chiffre_affaire_total += el.TotalValeurTTC
        });
      }
      let ca_label = this.type_ca == "MontantHT" ? "CA Brut" : "CA Net";
      this.chartjs.chartDataTVA = {
        labels: labels,
        datasets: [
          {
            label: ca_label,
            backgroundColor: this.arr_backcolor,
            data: datasets,
          },
        ],
      };

      this.exportTo.tva.data = [];
      this.exportTo.tva.data = this.reports.tva || [];
      if (this.reports.tva.length > 0) {
        this.exportTo.tva.columns = [];
        this.exportTo.tva.columns.push("LIBELLE");
        this.exportTo.tva.columns.push("TVA");
        this.exportTo.tva.columns.push("HT");
        this.exportTo.tva.columns.push("TTC");
      }
    },
    async getReportREMISE(from, to) {
      this.reports.remise = await this.ReportREMISE({
        url: "report/remise",
        page: 1,
        limit: 0,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      let labels = [];
      let datasets = [];
      let self = this;
      //self.chartjs.chiffre_affaire_total = 0
      if (self.reports.remise) {
        await self.reports.remise.map((el) => {
          labels.push(el._id.Libelle);
          datasets.push(el.TotalValeur);
        });
      }
      let ca_label = this.type_ca == "MontantHT" ? "CA Brut" : "CA Net";
      this.chartjs.chartDataREMISE = {
        labels: labels,
        datasets: [
          {
            label: ca_label,
            backgroundColor: this.arr_backcolor,
            data: datasets,
          },
        ],
      };

      this.exportTo.remise.data = [];
      this.exportTo.remise.data = this.reports.remise || [];
      if (this.reports.remise.length > 0) {
        this.exportTo.remise.columns = [];
        this.exportTo.remise.columns.push("LIBELLE");
        this.exportTo.remise.columns.push("Taux");
        this.exportTo.remise.columns.push("Valeur");
      }
    },
    async getStatistiques(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      this.reports.statistiques = await this.BoardRevenuStatistiques({
        url: "board/revenu-statistiques",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        groupbycaisse: "non",
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });

      this.exportTo.statistiques.data = [];
      this.exportTo.statistiques.data = this.reports.statistiques;
      if (this.reports.statistiques.length > 0) {
        this.exportTo.statistiques.columns = [];
        this.exportTo.statistiques.columns.push("Libelle");
        this.exportTo.statistiques.columns.push("Quantité");
        this.exportTo.statistiques.columns.push("Montant");
        this.exportTo.statistiques.columns.push("Moyenne");
      }
    },
    async getStatistiquesByCaisse(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      this.reports.statistiques_caisse = await this.BoardRevenuStatistiques({
        url: "board/revenu-statistiques",
        page: page,
        limit: limit,
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        groupbycaisse: "oui",
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });

      this.exportTo.statistiques_caisse.data = [];
      this.exportTo.statistiques_caisse.data = this.reports.statistiques_caisse;
      if (this.reports.statistiques_caisse.length > 0) {
        this.exportTo.statistiques_caisse.columns = [];
        this.exportTo.statistiques_caisse.columns.push("Libelle");
        this.exportTo.statistiques_caisse.columns.push("Quantité");
        this.exportTo.statistiques_caisse.columns.push("Montant");
        this.exportTo.statistiques_caisse.columns.push("Moyenne");
      }

    },
    async getReglementsGroups(from, to) {
      let qte = "qte";
      let vendeur = "vendeur";
      this.reports.reglements_by_libefamille = await this.getReglements({
        url: "entetes/reglements",
        range: this.datePicker.date.range,
        vendeur: "vendeur",
        site: this.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
      /*chart js*/
      let labels = [];
      let datasets = [];
      let self = this;
      self.chartjs.chiffre_affaire_total = 0;
      await self?.reports?.reglements_by_libefamille?.map((el) => {
        labels.push(el.LibFamille);
        datasets.push(el.TotalValeur);
        self.chartjs.chiffre_affaire_total += el.TotalValeur;
      });
      let ca_label = this.type_ca == "MontantHT" ? "CA Brut" : "CA Net";
      this.chartjs.chartDataReglements = {
        labels: labels,
        datasets: [
          {
            label: ca_label,
            backgroundColor: this.arr_backcolor,
            data: datasets,
          },
        ],
      };
      /*end chartjs */

      this.exportTo.reglements.data = [];
      this.exportTo.reglements.data = this.reports.reglements_by_libefamille;
      if (this.reports.reglements_by_libefamille.length > 0) {
        this.exportTo.reglements.columns = [];
        this.exportTo.reglements.columns.push("Type de paiement");
        this.exportTo.reglements.columns.push("Qte");
        this.exportTo.reglements.columns.push("Montant");
      }
    },

    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },
    callFunctions() {
      if (this.currentSite?._id) {
        let range = this.datePicker.defaultDates.type ? this.datePicker.defaultDates.type : this.datePicker.date.from
        this.getReportFamille(range, this.datePicker.date.to);
        this.getReportTVA(range, this.datePicker.date.to);
        this.getReportREMISE(range, this.datePicker.date.to);
        this.getStatistiques(range, this.datePicker.date.to);
        this.getStatistiquesByCaisse(range, this.datePicker.date.to);
        this.getReglementsGroups(range, this.datePicker.date.to);
        this.getReportJournalierTVA(range, this.datePicker.date.to);
        this.getReportJournalierPayments(range, this.datePicker.date.to);

      }
    },
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker", "globalSearch"]),
    dataReportFamille() {
      return this.reports.famille;
    },
    dataReportTVA() {
      let TotalValeurTVA = 0;
      let TotalValeurHT = 0;
      let TotalValeurTTC = 0;
      this.reports?.tva?.map((item) => {
        TotalValeurTVA = TotalValeurTVA + item.TotalValeurTVA;
        TotalValeurHT = TotalValeurHT + item.TotalValeurHT;
        TotalValeurTTC = TotalValeurTTC + item.TotalValeurTTC;
      });
      var results = [
        this.reports.tva,
        {
          TotalValeurTVA: TotalValeurTVA,
          TotalValeurHT: TotalValeurHT,
          TotalValeurTTC: TotalValeurTTC
        }
      ];
      return results;
    },
    dataReportREMISE() {
      let TotalValeur = 0;
      let TotalTaux = 0;
      this.reports?.remise?.map((item) => {
        TotalValeur = TotalValeur + item.TotalValeur;
        TotalTaux = TotalTaux + item.TotalTaux;
      });
      var results = [
        this.reports.remise,
        {
          TotalValeur: TotalValeur,
          TotalTaux: TotalTaux
        }
      ];
      return results;
    },
    dataReportReglements() {
      let TotalValeur = 0;
      let count = 0;
      let TotalValeurTTC = 0;
      this.reports?.reglements_by_libefamille?.map((item) => {
        TotalValeur = TotalValeur + item.TotalValeur;
        count = count + item.count;
        TotalValeurTTC = TotalValeurTTC + item.TotalValeurTTC;
      });
      var results = [
        this.reports.reglements_by_libefamille,
        {
          TotalValeur: TotalValeur,
          count: count
        }
      ];
      return results;
    },
    dataReportStatistiques() {
      return this.reports.statistiques;
    },
    dataReportStatistiquesCaisse() {
      return this.reports.statistiques_caisse;
    },
  },
};
</script>
<!-- eslint-disable -->
<style>
.hide {
  display: none;
}
</style>
