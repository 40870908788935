<!-- eslint-disable -->
<script setup>
import statistiquesPDF from "@/components/ToPDF/Statistiques/IndexPdf.vue";
import meilleursVentesPDF from "@/components/ToPDF/meilleursVentes/IndexPdf.vue";
import ventesParVendeurPDF from "@/components/ToPDF/ventesParVendeur/IndexPdf.vue";
//import ButtonDates from "@/components/ButtonDates.vue";
</script>
<!-- eslint-disable -->
<template>
  <!-- <ButtonDates /> -->
  <EditEtatVendeur @getListVendeursData="getListVendeurs" :vendeur="stateVendeur" />
  <div v-if="selectedUsers?.length > 0" class="row mb-3">
    <div class="col-md-6 mb-2">
      <button type="button" @click="editStatus(true)" class="btn btn-sm btn-primary w-100">
        Activer
      </button>
    </div>
    <div class="col-md-6 mb-2">
      <button type="button" @click="editStatus(false)" class="btn btn-sm btn-primary w-100">
        Désactiver
      </button>
    </div>
  </div>
  <div class="row">


    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">List des vendeurs</h3>
        </div>
        <div v-if="data.vendeurs && data.vendeurs.length > 0" class="card-body">
          <DataTable showGridlines stripedRows :value="data.vendeurs">
            <Column header="Cocher" sortable>
              <template #body="slotProps">
                <input type="checkbox" v-model="selectedUsers" :value="slotProps.data._id">
              </template>

            </Column>
            <Column field="LibVendeur" header="Nom" sortable> </Column>
            <Column field="isActive" header="Status" sortable>
              <template #body="slotProps">

                <span v-if="slotProps?.data?.isActive" class="table-tag" :style="{ backgroundColor: '#0be80b' }">
                  Actif
                </span>
                <span v-else-if="!slotProps?.data?.isActive" class="table-tag" :style="{ backgroundColor: '#fb6060' }">
                  Inactif
                </span>

              </template>
            </Column>
            <Column field="isActive" header="Actios" sortable>
              <template #body="slotProps">
                <button type="button" @click="stateVendeur = slotProps.data" data-bs-toggle="modal"
                  data-bs-target="#modalEdit" class="btn btn-sm btn-primary w-100">
                  <i class="fas fa-edit"></i>
                </button>
              </template>
            </Column>
          </DataTable>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { alertSwal } from "@/Utils/globale.js";

export default {
  components: { VueDatePicker, statistiquesPDF, meilleursVentesPDF, ventesParVendeurPDF },
  watch: {
    'datePicker.date.range'(newDate, oldDate) {
    }
  },
  data() {
    return {
      stateVendeur: null,
      exportTo: {
        vendeurs: {
          columns: [],
          data: [],
          fileName: "",
        },
      },
      data: {
        vendeurs: [],

      },
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 10,
        data: [],
      },
      searchs: {
        vendeurs: ["vendeur"],
      },
      interval: null,
      selectedUsers: []
    };
  },
  mounted() {
    const get = async () => {
      this.getListVendeurs();
    };
    if (this.isAuthenticated && this.isRole == 'manager')
      get();
  },
  created() {

  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "getVendeurs",
      "updateEtatVendeur"
    ]),

    async getListVendeurs() {
      const siteID = (this.currentSite != undefined) ? this.currentSite?._id : 'site'
      this.data.vendeurs = await this.getVendeurs({
        url: "vendeurs",
        site: siteID,
        vendeur: "vendeur",
        isActive: [true, false]

      });
    },

    async editStatus(isActive) {
      const vendeur = {
        ids: this.selectedUsers,
        isActive: isActive,
      };
      try {
        await this.updateEtatVendeur(vendeur);
        this.getListVendeurs()
        this.selectedUsers = []
        alertSwal('success', this.$t('alertswal.updated', { champ: 'Etat de vendeur' }))
      } catch (error) {
        if (error.response.status === 403) {
          alertSwal('error', this.$t('forbidden'))
          return;
        } else if (error.response.status === 404) {
          alertSwal('error', "error")
          return;
        }
        alertSwal("error", "Please Enter good informations");
      }
    },
  },
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
    ...mapState(["ticket_id", "datePicker"]),
  },
};
</script>
<!-- eslint-disable -->
<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}
</style>