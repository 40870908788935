/*eslint-disable*/
// sweetAlert.js

import { createApp } from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { utils, write, writeFile, WorkBook } from 'xlsx';

const app = createApp({});
app.use(VueSweetalert2);

export const alertSwal = (icon, title) => {
  const swal = app.config.globalProperties.$swal

  return swal({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    icon: icon,
    title: title,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", swal.stopTimer);
      toast.addEventListener("mouseleave", swal.resumeTimer);
    },
  });
};


export function setDefaultImage(event, defaultImage, imagePath) {
  const img = new Image();
  img.src = imagePath;
  img.onerror = () => {
    event.target.src = this.$baseUrl + defaultImage;
  };
}

export function setDefaultBackgroundImage(event, defaultImage, imagePath) {
  const img = new Image();
  img.src = imagePath;
  img.onerror = () => {
    event.target.style.backgroundImage = `url(${defaultImage})`;
  };
}

export function getImageUrl(pathImage) {
  if (pathImage) {
    return this.$baseUrl + pathImage
  } else {
    return '/default.png';
  }
}

export function Division(val1, val2) {
  var div = (val1 / val2)
  var res = (val2 > 0) ? div : 0;
  return res.toFixed(2)
}


