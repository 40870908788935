/*eslint-disable*/
import { Division, alertSwal } from "./globale.js";

import { createApp } from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { utils, write, writeFile, WorkBook } from 'xlsx';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import moment from "moment";
import dayjs from "dayjs";


const app = createApp({});
app.use(VueSweetalert2);




export function exportMultiTicketsToPdf(exportTo, type, format) {
    console.log(exportTo)
    // Initialize an empty result object
    const finalResult = {};
    // Function to accumulate sums for each array
    function calculateSumForArray(array, result) {

        array.forEach(item => {
            const { Libelle, ValeurTVA, ValeurHT, ValeurTTC } = item;

            // Initialize the Libelle if it doesn't exist in the result
            if (!result[Libelle]) {
                result[Libelle] = { ValeurTVA: 0, ValeurHT: 0, ValeurTTC: 0 };
            }

            // Accumulate the sums for each Libelle
            result[Libelle].ValeurTVA += ValeurTVA;
            result[Libelle].ValeurHT += ValeurHT;
            result[Libelle].ValeurTTC += ValeurTTC;
        });
    }



    let totalAmount = 0;
    var doc = new jsPDF({
        // orientation: 'portrait',
        // unit: 'mm',
        // format: format, //"a4",
        //  putOnlyUsedFonts: true,
        // precision: 20,
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,

    });


    let startY = 5;

    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth(); //60; /
    const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of one page

    function addDashedLine(yPosition, pageWidth) {
        startY += 5;
        doc.setFont("Courier", "normal", "normal");
        doc.setFontSize(6);
        const dashWidth = doc.getTextWidth('-'); // Width of one dash
        const totalDashes = Math.floor((pageWidth / dashWidth) - 3); // Calculate how many dashes fit
        const dashedLine = '-'.repeat(totalDashes); // Create the line
        doc.text(dashedLine, 3, startY);
    }


    function addNewHeight() {
        // Check if we exceed the page height
        if (startY + 10 > pageHeight) {
            doc.addPage(format); // Add a new page if needed
            startY = 5; // Reset Y position for the new page
            startY = startY; // Reset Y position for the new page
        }
    }

    let START_TEXT_POS_X = 3;

    let START_TEXT_POS_X_CENTER = pageWidth / 2;


    /*information de site*/
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(11);
    startY += 5
    doc.text("Information du restaurant", 3, startY, { align: "left" });
    startY += 6
    if (exportTo?.site?.LibSite) {
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(9);
        doc.text(exportTo.site.LibSite, 3, startY, { align: "left" });
    }
    doc.setFont("Courier", "normal", "normal");
    doc.setFontSize(7);
    if (exportTo?.site?.EmetteurSociete) {
        startY += 4;
        doc.text(exportTo.site.EmetteurSociete, 3, startY, { align: "left" });
    }

    let adresseSite = ''
    if (exportTo?.site?.EmetteurAdresse)
        adresseSite = adresseSite + "" + exportTo?.site?.EmetteurAdresse.toString()

    if (exportTo?.site?.EmetteurPays)
        adresseSite = adresseSite + ", " + exportTo?.site?.EmetteurPays.toString()


    let villePostal = ''
    if (exportTo?.site?.EmetteurCodePostal)
        villePostal = villePostal + "" + exportTo?.site?.EmetteurCodePostal.toString()
    if (exportTo?.site?.EmetteurVille)
        villePostal = villePostal + ", " + exportTo?.site?.EmetteurVille.toString()


    if (adresseSite) {
        startY += 4;
        doc.text("Adresse : " + adresseSite, 3, startY, { align: "left" });
    }
    if (villePostal) {
        startY += 4;
        doc.text("Ville : " + villePostal, 3, startY, { align: "left" });
    }

    // if (exportTo?.site?.EmetteurCodePostal) {
    //     startY += 4;
    //     doc.text(exportTo.site.EmetteurCodePostal.toString(), 3, startY, { align: "left" });
    // }

    // if (exportTo?.site?.EmetteurVille) {
    //     startY += 4;
    //     doc.text(exportTo.site.EmetteurVille.toString(), 3, startY, { align: "left" });
    // }

    // if (exportTo?.site?.EmetteurPays) {
    //     startY += 4;
    //     doc.text(exportTo.site.EmetteurPays.toString(), 3, startY, { align: "left" });
    // }

    if (exportTo?.site?.EmetteurTelephone) {
        startY += 4;
        doc.text("Téléphone : " + exportTo.site.EmetteurTelephone.toString(), 3, startY, { align: "left" });
    }

    if (exportTo?.site?.EmetteurCodeNAF) {
        startY += 4;
        doc.text("Code NAF : " + exportTo.site.EmetteurCodeNAF.toString(), 3, startY, { align: "left" });
    }
    if (exportTo?.site?.EmetteurNumTVA) {
        startY += 4;
        doc.text("Code NAF : " + exportTo.site.EmetteurNumTVA.toString(), 3, startY, { align: "left" });
    }

    if (exportTo?.site?.EmetteurSiret) {
        startY += 4;
        doc.text('SIRET : ' + exportTo.site.EmetteurSiret.toString(), 3, startY, { align: "left" });
    }


    /* doc.text(totalAmount.toFixed(2) + " €", pageWidth - 3,
    startY,
    "right");*/

    if (exportTo?.CONTACT) {
        let fakeStartY = 5
        fakeStartY += 6
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(11);
        doc.text("Information du client", pageWidth - 3, fakeStartY, { align: "right" });
        //addDashedLine(startY, pageWidth)

        fakeStartY += 5
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(9);
        if (exportTo.CONTACT?.Nom) {
            doc.text('Nom : ' + exportTo.CONTACT?.Nom.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(7);

        let ADRESSE = ''
        if (exportTo.CONTACT?.SITUATION?.ADRESSE) {
            ADRESSE = ADRESSE + " " + exportTo.CONTACT?.SITUATION?.ADRESSE
        }
        if (exportTo.CONTACT?.ADRESSE2) {
            ADRESSE = ADRESSE + ", " + exportTo.CONTACT?.ADRESSE2
        }
        if (exportTo.CONTACT?.PAYS)
            ADRESSE = ADRESSE + ", " + exportTo.CONTACT?.PAYS.toString()

        if (ADRESSE) {
            fakeStartY += 5
            doc.text('Adresse : ' + ADRESSE.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }


        let ville = ''
        if (exportTo.CONTACT?.SITUATION?.CODEPOSTAL)
            ville = ville + "" + exportTo.CONTACT?.SITUATION?.CODEPOSTAL.toString()
        if (exportTo.CONTACT?.SITUATION?.VILLE)
            ville = ville + ", " + exportTo.CONTACT?.SITUATION?.VILLE.toString()

        // if (exportTo.CONTACT?.SITUATION?.TYPE)
        //     pays = pays + ", " + exportTo.CONTACT?.SITUATION?.TYPE.toString()


        if (ville) {
            fakeStartY += 5
            doc.text('Ville  : ' + ville.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }


        if (exportTo.CONTACT?.TELEPHONE) {
            fakeStartY += 5
            doc.text('Téléphone : ' + exportTo.CONTACT?.TELEPHONE.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }
        if (exportTo.CONTACT?.EMAIL) {
            fakeStartY += 5
            doc.text('Email : ' + exportTo.CONTACT?.EMAIL.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }
    }
    startY += 14
    // doc.setDrawColor(0, 0, 0);
    // doc.line(3, startY, pageWidth - 3, startY);
    // addDashedLine(startY, pageWidth)
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(14);
    doc.text('FACTURE', START_TEXT_POS_X_CENTER, startY, { align: "center" });
    startY += 8



    exportTo.tickets.forEach((tick) => {


        let finalY = startY + 4

        // this for transfert
        /*        if (tick['transfertArticles']?.length > 0) {
        
                    // add line with startY +4
                    addDashedLine(startY, pageWidth)
                    startY += 4
                    doc.setFont("Courier", "normal", "bold");
                    doc.setFontSize(7);
                    doc.text("T : " + tick.data.NumTicket.toString(), START_TEXT_POS_X, startY, "left");
                    if (tick.data.Horodatage) {
                        moment.locale("fr");
                        doc.text(moment(tick.data.Horodatage).utc().format(
                            "MM/DD/YYYY HH:mm:ss"
                        ), pageWidth / 2,
                            startY,
                            "center");
                    }
                    doc.text(tick['totalTransferts'].toFixed(2) + " €", pageWidth - 3,
                        startY,
                        "right");
                    totalAmount = totalAmount + tick['totalTransferts']
                    finalY = finalY + 4
        
                    //
                    if (type == "with-details") {
                        finalY = startY + 2;
                        var element = [];
                        tick['transfertArticles']?.map((article) => {
                            var Libelle = ''
                            var TotalNet = ''
                            var UnitaireTTC = ''
                            var NetHT = ''
                            //el?.ARTICLE?.map((article) => {
                            if (article?.OFFERT) {
                                Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                                TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                                    UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                                NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                            } else {
                                Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                                TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                                UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                                NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
                            }
                            element.push([
                                Libelle,
                                UnitaireTTC,
                                NetHT + " €",
                                TotalNet + " €"
                            ]);
                            //  });
                            const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
                            const tableWidth = pageWidth - 6; // Total width of the table
                            const columnCount = cols.length; // Assuming all rows have the same number of columns
                            /////console.log(columnCount)
                            const columnWidth = tableWidth / columnCount;
                            const FirstcellWidth = columnWidth + 15
                            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
                            // Set the starting position of the table
                            const startXX = 20;
                            let currentX = startXX;
        
                            // Calculate the column widths and adjust the X-coordinate of each cell
                            const columnStyles = {};
                            for (let i = 0; i < columnCount; i++) {
                                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                                columnStyles[i] = { cellWidth: cellWidth };
                                columnStyles[i].cellX = currentX;
                                //columnStyles[i].halign = 'left';
        
                                currentX += cellWidth;
                            }
                            const styles = {
                                overflow: 'linebreak',
                                fontSize: 7, // Font size for the table body
                                cellWidth: 'auto', // Allow the column width to adjust automatically
                                halign: 'left'
        
                            };
                            console.log(columnStyles)
                            const options = {
                                startY: finalY, // Adjust the vertical position of the table
                                // Set column widths
                                styles: styles, // Set font sizes
        
                                //  margin: { left: 0 },
                                headStyles: {
                                    fontSize: 6
                                }
                            };
                            doc.autoTable({
                                margin: { top: 10, left: 3, right: 0 },
                                pageBreak: "auto",
                                theme: "plain",
        
                                headStyles: {
                                    fillColor: [29, 30, 34],
                                    textColor: [255, 255, 255],
                                    cellPadding: 3,
                                },
                                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                                bodyStyles: { cellPadding: 1 },
                                head: [cols],
                                body: element, // [el.GUID], //.slice(1),
                                ...options, // Spread the options object
                                columnStyles: columnStyles,
                            });
                            addNewHeight()
                        });
                        finalY = doc.autoTable.previous.finalY + 4
                        startY = finalY;
                    }
                }*/


        /*** THIS FOR TVA */
        //  if (type == "with-details") {
        /*   if (tick['TransfertTVA']) {
               if (Object.keys(tick['TransfertTVA']).length > 0) {
   
                   const TVA = (Array.isArray(tick['TransfertTVA'])) ? Object.values(tick['TransfertTVA'])[0] : [Object.values(tick['TransfertTVA'])[0]]
                   var element = [];
                   if (TVA) {
                       calculateSumForArray(TVA, finalResult);
                   }
   
   
                    finalY = doc.autoTable.previous.finalY
                   var element = [];
                   // add line with startY +4
                   addDashedLine(startY, pageWidth)
                   Object.entries(tick['TransfertTVA']).forEach(([code, el]) => {
                       element.push([
                           el?.Libelle,
                           Number(el?.ValeurTVA).toFixed(2).toString() + " €",
                           Number(el?.ValeurHT).toFixed(2).toString() + " €",
                           Number(el?.ValeurTTC).toFixed(2).toString() + " €"
                       ]);
    
                       const cols = ["CODE", "TVA", "HT", "TTC"]
                       const tableWidth = pageWidth - 6; // Total width of the table
                       const columnCount = cols.length; // Assuming all rows have the same number of columns
                       /////console.log(columnCount)
                       const columnWidth = tableWidth / columnCount;
                       const FirstcellWidth = columnWidth + 15
                       const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
    
                       let currentX = 20;
    
                       // Calculate the column widths and adjust the X-coordinate of each cell
                       const columnStyles = {};
                       for (let i = 0; i < columnCount; i++) {
                           const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                           columnStyles[i] = { cellWidth: cellWidth };
                           columnStyles[i].cellX = currentX;
                           currentX += cellWidth;
                       }
                       const styles = {
                           fontSize: 7, // Font size for the table body
                           cellWidth: 'auto', // Allow the column width to adjust automatically
                            
                       };
    
                       const options = {
                           startY: finalY, // Adjust the vertical position of the table
                           columnStyles: columnStyles, // Set column widths
                           styles: styles, // Set font sizes
                            
                           //  margin: { left: 0 },
                           headStyles: {
                               fontSize: 6
                           }
    
                       };
                       doc.autoTable({
                           margin: { top: 5, left: 3, right: 0 },
                           pageBreak: "auto",
                           theme: "plain",
                            
                           headStyles: {
                               fillColor: [29, 30, 34],
                               textColor: [255, 255, 255],
                               cellPadding: 3,
                           },
                           footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                           bodyStyles: { cellPadding: 1 },
                           head: [cols],
                           body: element,
                           ...options, // Spread the options object
                       });
                   });
    
                   finalY = doc.autoTable.previous.finalY + 4
                   startY = finalY; 
               }
           }*/
        // }
        addNewHeight()
        if (tick['arr_commandes']?.length > 0) {
            // add line with startY +4
            addDashedLine(startY, pageWidth)
            startY += 4
            doc.setFont("Courier", "normal", "bold");
            doc.setFontSize(7);
            doc.text("T : " + tick.data.NumTicket.toString(), START_TEXT_POS_X, startY, "left");
            if (tick.data.Horodatage) {
                moment.locale("fr");
                doc.text(moment(tick.data.Horodatage).utc().format(
                    "MM/DD/YYYY HH:mm:ss"
                ), pageWidth / 2,
                    startY,
                    "center");
            }
            doc.text((tick.data.MontantTTC).toFixed(2) + " €", pageWidth - 3,
                startY,
                "right");
            totalAmount = totalAmount + tick.data.MontantTTC
            finalY = finalY + 4
            var element = [];
            if (type == "with-details") {
                tick['arr_commandes']?.map((el) => {
                    var Libelle = ''
                    var TotalNet = ''
                    var UnitaireTTC = ''
                    var NetHT = ''
                    if (el?.ARTICLE) {
                        el?.ARTICLE?.map((article) => {
                            if (article?.OFFERT) {
                                Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                                TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                                    UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                                NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                            } else {
                                Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                                TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                                UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                                NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
                            }
                            element.push([
                                Libelle,
                                UnitaireTTC + " €",
                                NetHT + " €",
                                TotalNet + " €"
                            ]);
                        });
                        const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
                        const tableWidth = pageWidth - 6; // Total width of the table
                        const columnCount = cols.length; // Assuming all rows have the same number of columns
                        /////console.log(columnCount)
                        const columnWidth = tableWidth / columnCount;
                        const FirstcellWidth = columnWidth + 15
                        const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
                        // Set the starting position of the table
                        const startXX = 20;
                        let currentX = startXX;

                        // Calculate the column widths and adjust the X-coordinate of each cell
                        const columnStyles = {};
                        for (let i = 0; i < columnCount; i++) {
                            const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                            columnStyles[i] = { cellWidth: cellWidth };
                            columnStyles[i].cellX = currentX;
                            //columnStyles[i].halign = 'left';

                            currentX += cellWidth;
                        }
                        const styles = {
                            fontSize: 7, // Font size for the table body
                            cellWidth: 'auto', // Allow the column width to adjust automatically
                            overflow: 'linebreak',
                            halign: 'left'

                        };
                        console.log(columnStyles)
                        finalY += 4
                        const options = {
                            startY: finalY, // Adjust the vertical position of the table
                            styles: styles, // Set font sizes

                            //margin: { left: 0 },
                            headStyles: {
                                fontSize: 6
                            }
                        };
                        doc.autoTable({
                            margin: { top: 5, left: 3, right: 0 },
                            pageBreak: "auto",
                            theme: "plain",

                            headStyles: {
                                fillColor: [29, 30, 34],
                                textColor: [255, 255, 255],
                                cellPadding: 3,
                            },
                            footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                            bodyStyles: { cellPadding: 1 },
                            head: [cols],
                            body: element, // [el.GUID], //.slice(1),
                            ...options, // Spread the options object
                            columnStyles: columnStyles,
                        });
                        finalY = doc.autoTable.previous.finalY
                        addNewHeight()
                    }

                });
            }
        }

        if (type == "with-details") {
            startY = finalY;
        } else {
            finalY = startY
        }
        startY = finalY;

        /*** THIS FOR TVA */
        if (tick?.data?.TVA) {
            const TVA = (Array.isArray(tick?.data?.TVA)) ? tick.data.TVA : [tick.data.TVA]
            var element = [];
            if (TVA) {
                calculateSumForArray(TVA, finalResult);
            }
        }
    })
    addNewHeight()
    startY += 4
    doc.setDrawColor(0, 0, 0);
    doc.line(3, startY, pageWidth - 3, startY);
    startY += 4
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(8);
    console.log("finalResult")
    console.log(finalResult)
    Object.entries(finalResult).forEach(([key, value]) => {
        startY += 4
        doc.text("Montant HT " + key + " : ", START_TEXT_POS_X, startY, "left");
        doc.text(value.ValeurHT.toFixed(2) + " €", pageWidth - 3,
            startY,
            "right");

        startY += 4
        doc.text("Total " + key + " : ", START_TEXT_POS_X, startY, "left");
        doc.text(value.ValeurTVA.toFixed(2) + " €", pageWidth - 3,
            startY,
            "right");

        startY += 4
        doc.text("Total TTC " + key + " : ", START_TEXT_POS_X, startY, "left");
        doc.text(value.ValeurTTC.toFixed(2) + " €", pageWidth - 3,
            startY,
            "right");
        startY += 4
    });
    addNewHeight()
    startY += 4
    doc.setDrawColor(0, 0, 0);
    doc.line(3, startY, pageWidth - 3, startY);

    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(10);
    startY += 5
    doc.text("TOTAL : ", START_TEXT_POS_X, startY, "left");
    doc.text(totalAmount.toFixed(2) + " €", pageWidth - 3,
        startY,
        "right");


    // Function to format the date and time
    function formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        return date.toLocaleString('fr-FR', options).replace(/[/ :]/g, '-'); // Change the format and replace separators
    }

    // Generate the file name
    const dateTime = formatDate(new Date());
    const fileName = `exportation-tickets-${dateTime}.pdf`;
    doc.save(fileName);
}



export function exportMultiTicketsToMail(exportTo, type, format) {
    console.log(exportTo)
    // Initialize an empty result object
    const finalResult = {};
    // Function to accumulate sums for each array
    function calculateSumForArray(array, result) {

        array.forEach(item => {
            const { Libelle, ValeurTVA, ValeurHT, ValeurTTC } = item;

            // Initialize the Libelle if it doesn't exist in the result
            if (!result[Libelle]) {
                result[Libelle] = { ValeurTVA: 0, ValeurHT: 0, ValeurTTC: 0 };
            }

            // Accumulate the sums for each Libelle
            result[Libelle].ValeurTVA += ValeurTVA;
            result[Libelle].ValeurHT += ValeurHT;
            result[Libelle].ValeurTTC += ValeurTTC;
        });
    }



    let totalAmount = 0;
    var doc = new jsPDF({
        // orientation: 'portrait',
        // unit: 'mm',
        // format: format, //"a4",
        //  putOnlyUsedFonts: true,
        // precision: 20,
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,

    });


    let startY = 5;

    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth(); //60; /
    const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of one page

    function addDashedLine(yPosition, pageWidth) {
        startY += 5;
        doc.setFont("Courier", "normal", "normal");
        doc.setFontSize(6);
        const dashWidth = doc.getTextWidth('-'); // Width of one dash
        const totalDashes = Math.floor((pageWidth / dashWidth) - 3); // Calculate how many dashes fit
        const dashedLine = '-'.repeat(totalDashes); // Create the line
        doc.text(dashedLine, 3, startY);
    }


    function addNewHeight() {
        // Check if we exceed the page height
        if (startY + 10 > pageHeight) {
            doc.addPage(format); // Add a new page if needed
            startY = 5; // Reset Y position for the new page
            startY = startY; // Reset Y position for the new page
        }
    }

    let START_TEXT_POS_X = 3;

    let START_TEXT_POS_X_CENTER = pageWidth / 2;


    /*information de site*/
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(11);
    startY += 5
    doc.text("Information du restaurant", 3, startY, { align: "left" });
    startY += 6
    if (exportTo?.site?.LibSite) {
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(9);
        doc.text(exportTo.site.LibSite, 3, startY, { align: "left" });
    }
    doc.setFont("Courier", "normal", "normal");
    doc.setFontSize(7);
    if (exportTo?.site?.EmetteurSociete) {
        startY += 4;
        doc.text(exportTo.site.EmetteurSociete, 3, startY, { align: "left" });
    }

    let adresseSite = ''
    if (exportTo?.site?.EmetteurAdresse)
        adresseSite = adresseSite + "" + exportTo?.site?.EmetteurAdresse.toString()

    if (exportTo?.site?.EmetteurPays)
        adresseSite = adresseSite + ", " + exportTo?.site?.EmetteurPays.toString()


    let villePostal = ''
    if (exportTo?.site?.EmetteurCodePostal)
        villePostal = villePostal + "" + exportTo?.site?.EmetteurCodePostal.toString()
    if (exportTo?.site?.EmetteurVille)
        villePostal = villePostal + ", " + exportTo?.site?.EmetteurVille.toString()


    if (adresseSite) {
        startY += 4;
        doc.text("Adresse : " + adresseSite, 3, startY, { align: "left" });
    }
    if (villePostal) {
        startY += 4;
        doc.text("Ville : " + villePostal, 3, startY, { align: "left" });
    }

    // if (exportTo?.site?.EmetteurCodePostal) {
    //     startY += 4;
    //     doc.text(exportTo.site.EmetteurCodePostal.toString(), 3, startY, { align: "left" });
    // }

    // if (exportTo?.site?.EmetteurVille) {
    //     startY += 4;
    //     doc.text(exportTo.site.EmetteurVille.toString(), 3, startY, { align: "left" });
    // }

    // if (exportTo?.site?.EmetteurPays) {
    //     startY += 4;
    //     doc.text(exportTo.site.EmetteurPays.toString(), 3, startY, { align: "left" });
    // }

    if (exportTo?.site?.EmetteurTelephone) {
        startY += 4;
        doc.text("Téléphone : " + exportTo.site.EmetteurTelephone.toString(), 3, startY, { align: "left" });
    }

    if (exportTo?.site?.EmetteurCodeNAF) {
        startY += 4;
        doc.text("Code NAF : " + exportTo.site.EmetteurCodeNAF.toString(), 3, startY, { align: "left" });
    }
    if (exportTo?.site?.EmetteurNumTVA) {
        startY += 4;
        doc.text("Code NAF : " + exportTo.site.EmetteurNumTVA.toString(), 3, startY, { align: "left" });
    }

    if (exportTo?.site?.EmetteurSiret) {
        startY += 4;
        doc.text('SIRET : ' + exportTo.site.EmetteurSiret.toString(), 3, startY, { align: "left" });
    }


    /* doc.text(totalAmount.toFixed(2) + " €", pageWidth - 3,
    startY,
    "right");*/

    if (exportTo?.CONTACT) {
        let fakeStartY = 5
        fakeStartY += 6
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(11);
        doc.text("Information du client", pageWidth - 3, fakeStartY, { align: "right" });
        //addDashedLine(startY, pageWidth)

        fakeStartY += 5
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(9);
        if (exportTo.CONTACT?.Nom) {
            doc.text('Nom : ' + exportTo.CONTACT?.Nom.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(7);

        let ADRESSE = ''
        if (exportTo.CONTACT?.SITUATION?.ADRESSE) {
            ADRESSE = ADRESSE + " " + exportTo.CONTACT?.SITUATION?.ADRESSE
        }
        if (exportTo.CONTACT?.ADRESSE2) {
            ADRESSE = ADRESSE + ", " + exportTo.CONTACT?.ADRESSE2
        }
        if (exportTo.CONTACT?.PAYS)
            ADRESSE = ADRESSE + ", " + exportTo.CONTACT?.PAYS.toString()

        if (ADRESSE) {
            fakeStartY += 5
            doc.text('Adresse : ' + ADRESSE.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }


        let ville = ''
        if (exportTo.CONTACT?.SITUATION?.CODEPOSTAL)
            ville = ville + "" + exportTo.CONTACT?.SITUATION?.CODEPOSTAL.toString()
        if (exportTo.CONTACT?.SITUATION?.VILLE)
            ville = ville + ", " + exportTo.CONTACT?.SITUATION?.VILLE.toString()

        // if (exportTo.CONTACT?.SITUATION?.TYPE)
        //     pays = pays + ", " + exportTo.CONTACT?.SITUATION?.TYPE.toString()


        if (ville) {
            fakeStartY += 5
            doc.text('Ville  : ' + ville.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }


        if (exportTo.CONTACT?.TELEPHONE) {
            fakeStartY += 5
            doc.text('Téléphone : ' + exportTo.CONTACT?.TELEPHONE.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }
        if (exportTo.CONTACT?.EMAIL) {
            fakeStartY += 5
            doc.text('Email : ' + exportTo.CONTACT?.EMAIL.toString(), pageWidth - 3, fakeStartY, { align: "right" });
        }
    }
    startY += 14
    // doc.setDrawColor(0, 0, 0);
    // doc.line(3, startY, pageWidth - 3, startY);
    // addDashedLine(startY, pageWidth)
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(14);
    doc.text('FACTURE', START_TEXT_POS_X_CENTER, startY, { align: "center" });
    startY += 8



    exportTo.tickets.forEach((tick) => {


        let finalY = startY + 4

        // this for transfert
        if (tick['transfertArticles']?.length > 0) {

            // add line with startY +4
            addDashedLine(startY, pageWidth)
            startY += 4
            doc.setFont("Courier", "normal", "bold");
            doc.setFontSize(7);
            doc.text("T : " + tick.data.NumTicket.toString(), START_TEXT_POS_X, startY, "left");
            if (tick.data.Horodatage) {
                moment.locale("fr");
                doc.text(moment(tick.data.Horodatage).utc().format(
                    "MM/DD/YYYY HH:mm:ss"
                ), pageWidth / 2,
                    startY,
                    "center");
            }
            doc.text(tick['totalTransferts'].toFixed(2) + " €", pageWidth - 3,
                startY,
                "right");
            totalAmount = totalAmount + tick['totalTransferts']
            finalY = finalY + 4

            //
            if (type == "with-details") {
                finalY = startY + 2;
                var element = [];
                tick['transfertArticles']?.map((article) => {
                    var Libelle = ''
                    var TotalNet = ''
                    var UnitaireTTC = ''
                    var NetHT = ''
                    //el?.ARTICLE?.map((article) => {
                    if (article?.OFFERT) {
                        Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                        TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                            UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                        NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                    } else {
                        Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                        TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                        UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                        NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
                    }
                    element.push([
                        Libelle,
                        UnitaireTTC,
                        NetHT + " €",
                        TotalNet + " €"
                    ]);
                    //  });
                    const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
                    const tableWidth = pageWidth - 6; // Total width of the table
                    const columnCount = cols.length; // Assuming all rows have the same number of columns
                    /////console.log(columnCount)
                    const columnWidth = tableWidth / columnCount;
                    const FirstcellWidth = columnWidth + 15
                    const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
                    // Set the starting position of the table
                    const startXX = 20;
                    let currentX = startXX;

                    // Calculate the column widths and adjust the X-coordinate of each cell
                    const columnStyles = {};
                    for (let i = 0; i < columnCount; i++) {
                        const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                        columnStyles[i] = { cellWidth: cellWidth };
                        columnStyles[i].cellX = currentX;
                        //columnStyles[i].halign = 'left';

                        currentX += cellWidth;
                    }
                    const styles = {
                        overflow: 'linebreak',
                        fontSize: 7, // Font size for the table body
                        cellWidth: 'auto', // Allow the column width to adjust automatically
                        halign: 'left'

                    };
                    console.log(columnStyles)
                    const options = {
                        startY: finalY, // Adjust the vertical position of the table
                        // Set column widths
                        styles: styles, // Set font sizes

                        //  margin: { left: 0 },
                        headStyles: {
                            fontSize: 6
                        }
                    };
                    doc.autoTable({
                        margin: { top: 10, left: 3, right: 0 },
                        pageBreak: "auto",
                        theme: "plain",

                        headStyles: {
                            fillColor: [29, 30, 34],
                            textColor: [255, 255, 255],
                            cellPadding: 3,
                        },
                        footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                        bodyStyles: { cellPadding: 1 },
                        head: [cols],
                        body: element, // [el.GUID], //.slice(1),
                        ...options, // Spread the options object
                        columnStyles: columnStyles,
                    });
                    addNewHeight()
                });
                finalY = doc.autoTable.previous.finalY + 4
                startY = finalY;
            }
        }

        /**tva transfert */
        /*** THIS FOR TVA */
        //  if (type == "with-details") {
        if (tick['TransfertTVA']) {
            if (Object.keys(tick['TransfertTVA']).length > 0) {

                const TVA = (Array.isArray(tick['TransfertTVA'])) ? Object.values(tick['TransfertTVA'])[0] : [Object.values(tick['TransfertTVA'])[0]]
                var element = [];
                if (TVA) {
                    calculateSumForArray(TVA, finalResult);
                }


                /*finalY = doc.autoTable.previous.finalY
                var element = [];
                // add line with startY +4
                addDashedLine(startY, pageWidth)
                Object.entries(tick['TransfertTVA']).forEach(([code, el]) => {
                    element.push([
                        el?.Libelle,
                        Number(el?.ValeurTVA).toFixed(2).toString() + " €",
                        Number(el?.ValeurHT).toFixed(2).toString() + " €",
                        Number(el?.ValeurTTC).toFixed(2).toString() + " €"
                    ]);
 
                    const cols = ["CODE", "TVA", "HT", "TTC"]
                    const tableWidth = pageWidth - 6; // Total width of the table
                    const columnCount = cols.length; // Assuming all rows have the same number of columns
                    /////console.log(columnCount)
                    const columnWidth = tableWidth / columnCount;
                    const FirstcellWidth = columnWidth + 15
                    const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
 
                    let currentX = 20;
 
                    // Calculate the column widths and adjust the X-coordinate of each cell
                    const columnStyles = {};
                    for (let i = 0; i < columnCount; i++) {
                        const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                        columnStyles[i] = { cellWidth: cellWidth };
                        columnStyles[i].cellX = currentX;
                        currentX += cellWidth;
                    }
                    const styles = {
                        fontSize: 7, // Font size for the table body
                        cellWidth: 'auto', // Allow the column width to adjust automatically
                         
                    };
 
                    const options = {
                        startY: finalY, // Adjust the vertical position of the table
                        columnStyles: columnStyles, // Set column widths
                        styles: styles, // Set font sizes
                         
                        //  margin: { left: 0 },
                        headStyles: {
                            fontSize: 6
                        }
 
                    };
                    doc.autoTable({
                        margin: { top: 5, left: 3, right: 0 },
                        pageBreak: "auto",
                        theme: "plain",
                         
                        headStyles: {
                            fillColor: [29, 30, 34],
                            textColor: [255, 255, 255],
                            cellPadding: 3,
                        },
                        footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                        bodyStyles: { cellPadding: 1 },
                        head: [cols],
                        body: element,
                        ...options, // Spread the options object
                    });
                });
 
                finalY = doc.autoTable.previous.finalY + 4
                startY = finalY;*/
            }
        }
        // }
        addNewHeight()
        if (tick['arr_commandes']?.length > 0) {
            // add line with startY +4
            addDashedLine(startY, pageWidth)
            startY += 4
            doc.setFont("Courier", "normal", "bold");
            doc.setFontSize(7);
            doc.text("T : " + tick.data.NumTicket.toString(), START_TEXT_POS_X, startY, "left");
            if (tick.data.Horodatage) {
                moment.locale("fr");
                doc.text(moment(tick.data.Horodatage).utc().format(
                    "MM/DD/YYYY HH:mm:ss"
                ), pageWidth / 2,
                    startY,
                    "center");
            }
            doc.text((tick.data.MontantTTC).toFixed(2) + " €", pageWidth - 3,
                startY,
                "right");
            totalAmount = totalAmount + tick.data.MontantTTC
            finalY = finalY + 4
            var element = [];
            if (type == "with-details") {
                tick['arr_commandes']?.map((el) => {
                    var Libelle = ''
                    var TotalNet = ''
                    var UnitaireTTC = ''
                    var NetHT = ''
                    if (el?.ARTICLE) {
                        el?.ARTICLE?.map((article) => {
                            if (article?.OFFERT) {
                                Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                                TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                                    UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                                NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                            } else {
                                Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                                TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                                UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                                NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
                            }
                            element.push([
                                Libelle,
                                UnitaireTTC + " €",
                                NetHT + " €",
                                TotalNet + " €"
                            ]);
                        });
                        const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
                        const tableWidth = pageWidth - 6; // Total width of the table
                        const columnCount = cols.length; // Assuming all rows have the same number of columns
                        /////console.log(columnCount)
                        const columnWidth = tableWidth / columnCount;
                        const FirstcellWidth = columnWidth + 15
                        const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
                        // Set the starting position of the table
                        const startXX = 20;
                        let currentX = startXX;

                        // Calculate the column widths and adjust the X-coordinate of each cell
                        const columnStyles = {};
                        for (let i = 0; i < columnCount; i++) {
                            const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                            columnStyles[i] = { cellWidth: cellWidth };
                            columnStyles[i].cellX = currentX;
                            //columnStyles[i].halign = 'left';

                            currentX += cellWidth;
                        }
                        const styles = {
                            fontSize: 7, // Font size for the table body
                            cellWidth: 'auto', // Allow the column width to adjust automatically
                            overflow: 'linebreak',
                            halign: 'left'

                        };
                        console.log(columnStyles)
                        finalY += 4
                        const options = {
                            startY: finalY, // Adjust the vertical position of the table
                            styles: styles, // Set font sizes

                            //margin: { left: 0 },
                            headStyles: {
                                fontSize: 6
                            }
                        };
                        doc.autoTable({
                            margin: { top: 5, left: 3, right: 0 },
                            pageBreak: "auto",
                            theme: "plain",

                            headStyles: {
                                fillColor: [29, 30, 34],
                                textColor: [255, 255, 255],
                                cellPadding: 3,
                            },
                            footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                            bodyStyles: { cellPadding: 1 },
                            head: [cols],
                            body: element, // [el.GUID], //.slice(1),
                            ...options, // Spread the options object
                            columnStyles: columnStyles,
                        });
                        finalY = doc.autoTable.previous.finalY
                        addNewHeight()
                    }

                });
            }
        }

        if (type == "with-details") {
            startY = finalY;
        } else {
            finalY = startY
        }
        startY = finalY;

        /*** THIS FOR TVA */
        if (tick?.data?.TVA) {
            const TVA = (Array.isArray(tick?.data?.TVA)) ? tick.data.TVA : [tick.data.TVA]
            var element = [];
            if (TVA) {
                calculateSumForArray(TVA, finalResult);
            }
        }
    })
    addNewHeight()
    startY += 4
    doc.setDrawColor(0, 0, 0);
    doc.line(3, startY, pageWidth - 3, startY);
    startY += 4
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(8);
    console.log("finalResult")
    console.log(finalResult)
    Object.entries(finalResult).forEach(([key, value]) => {
        startY += 4
        doc.text("Montant HT " + key + " : ", START_TEXT_POS_X, startY, "left");
        doc.text(value.ValeurHT.toFixed(2) + " €", pageWidth - 3,
            startY,
            "right");

        startY += 4
        doc.text("Total " + key + " : ", START_TEXT_POS_X, startY, "left");
        doc.text(value.ValeurTVA.toFixed(2) + " €", pageWidth - 3,
            startY,
            "right");

        startY += 4
        doc.text("Total TTC " + key + " : ", START_TEXT_POS_X, startY, "left");
        doc.text(value.ValeurTTC.toFixed(2) + " €", pageWidth - 3,
            startY,
            "right");
        startY += 4
    });
    addNewHeight()
    startY += 4
    doc.setDrawColor(0, 0, 0);
    doc.line(3, startY, pageWidth - 3, startY);

    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(10);
    startY += 5
    doc.text("TOTAL : ", START_TEXT_POS_X, startY, "left");
    doc.text(totalAmount.toFixed(2) + " €", pageWidth - 3,
        startY,
        "right");


    // Function to format the date and time
    function formatDate(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        return date.toLocaleString('fr-FR', options).replace(/[/ :]/g, '-'); // Change the format and replace separators
    }

    // Generate the base64-encoded representation of the PDF
    const base64PDF = doc.output('dataurlstring');

    // Convert base64 to Buffer
    const pdfBuffer = base64PDF.split('base64,')[1]


    return pdfBuffer

}
