<!-- eslint-disable -->
<script setup>
import ticketPDF from "@/components/ToPDF/tickets/IndexPdf.vue";
import multiTicketPDF from "@/components/ToPDF/tickets/multiTicketPDF.vue";
</script>
<!-- eslint-disable -->
<template>
  <div class="row">
    <div class="col-md-12">
      <span type="button" class="col-btn bg-white border-1-solid text-center">
        <multiTicketPDF :exportTo="exportTo" type="without-details" />
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" v-for="(tick, ind ) in exportTo.tickets">
      <div class="row">
        <div class="col-md-12 export-btn grid-3 mb-3 text-end">
          <span v-if="tick.arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <ticketPDF :exportTo="tick" />
          </span>

        </div>
      </div>
    </div>
  </div>
  <div class="row" v-if="ticket_mongoDB" v-for="(element, index) in ticket_mongoDB">
    <div class="col-md-8 mb-4">
      <div class="type_ca float-start">
        <router-link :to="{ name: 'tickets' }"> Back </router-link>
      </div>
    </div>
    <div class="col-md-4">

      <div class="row">
        <div class="col-md-12 export-btn grid-3 mb-3 text-end">
          <span v-if="arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <ticketPDF :exportTo="exportTo.ticket" />
          </span>
          <span v-if="arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center">
            <ticketExcel :exportTo="exportTo.ticket" />
          </span>
          <span v-if="arr_commandes.length" type="button" class="col-btn bg-white border-1-solid text-center ">
            <div data-bs-toggle="modal" data-bs-target="#modalSendMail"> <i class="far fa-envelope"></i> Envoyer
            </div>
          </span>
        </div>
      </div>
    </div>

    <div class="col-md-12 justify-content-between align-items-center flex-wrap grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="row mt-2">
            <div v-if="element.LibSite" class="col-12 col-md-12 col-xl-12  mb-5">
              <div class="text-center">
                <h3>Site : {{ element.LibSite }}</h3>
              </div>
            </div>
            <div v-if="element.NumTicket" class="col-12 col-md-12 col-xl-12  mb-5">
              <div class="text-center">
                <h4>Numéro de ticket : {{ element.NumTicket }}</h4>
              </div>
            </div>
            <div class="col-12 col-md-12 col-xl-12">
              <div class="row">

                <div v-if="element.LibVendeur" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Vendeur</b> : {{ element.LibVendeur }}
                  </p>
                </div>


                <div v-if="element.Operation" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Opération</b> : {{ element.Operation }}
                  </p>
                </div>
                <div v-if="element.Etat" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Etat</b> : {{ element.Etat }}
                  </p>
                </div>
                <div v-if="element.Horodatage" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Date </b> :
                    {{ $dayjs(element.Horodatage).utc().format("MM/DD/YYYY HH:mm:ss") }}
                  </p>
                </div>

                <div v-if="element.EmetteurTelephone" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Téléphone </b> : {{ element.EmetteurTelephone }}
                  </p>
                </div>

                <div v-if="element.LibCaisse" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Caisse </b> : {{ element.LibCaisse }}
                  </p>
                </div>
                <div v-if="element.MontantHT" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Montant HT</b> : {{ (element.MontantHT).toFixed(2).toString() }} €
                  </p>
                </div>
                <div v-if="element.MontantTVA" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Montant TVA</b> : {{ (element.MontantTVA).toFixed(2).toString() }} €
                  </p>
                </div>
                <div v-if="element.MontantTTC" class="col-md-3 mb-3">
                  <p class="mb-2 text-muteds info-restautant">
                    <b>Montant TTC</b> : {{ (element.MontantTTC).toFixed(2).toString() }} €
                  </p>
                </div>
              </div>
            </div>


            <div v-if="transfertArticles?.length > 0" class="col-12 col-md-12 col-xl-12 mt-5 =">
              <div class="table-responsive ">
                <DataTable showGridlines stripedRows :value="transfertArticles">
                  <Column header="DESIGNATION" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Quantite).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        ({{ slotProps?.data?.OFFERT?.Libelle }})
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.QuantiteNet).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        <span v-if="slotProps?.data?.ANNULATION">
                          ( {{ slotProps?.data?.ANNULATION?.Libelle }})
                        </span>
                      </span>
                    </template>
                  </Column>
                  <Column header="Prix UT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixUnitaireTTC).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Prix HT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNetHT).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Total" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNet).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Total" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.LibTVA }}
                      </span>
                    </template>
                  </Column>

                </DataTable>

                <!-- <table class="table table-striped table-hovers caption-top">
                  <thead class="table-dark">
                    <tr class="">
                      <th>DESIGNATION</th>
                      <th class="text-end">Prix UT</th>
                      <th class="text-end">Prix HT</th>
                      <th class="text-end">Total</th>
                      <th class="text-end">TVA</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(article, index_article) in transfertArticles" :key="index_article">
                      <td>
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Quantite).toFixed(0) }}
                          {{ article.Libelle }}
                          ({{ article?.OFFERT?.Libelle }})
                        </span>
                        <span v-else>
                          {{ Number(article.QuantiteNet).toFixed(0) }}
                          {{ article.Libelle }}
                          <span v-if="article?.ANNULATION">
                            ( {{ article?.ANNULATION?.Libelle }})
                          </span>
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixUnitaireTTC).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNetHT).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                           {{ Number(article.PrixTotalNet).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        {{ article.LibTVA }}
                      </td>
                     
                    </tr>
                   
                  </tbody>
                </table> -->
              </div>
            </div>
            <div v-if="transfertArticles?.length > 0 && TransfertTVA" class="col-12 col-md-12 col-xl-12 mt-3  mb-3">
              <div class="table-responsive mb-5">
                <DataTable showGridlines stripedRows :value="TransfertTVA">
                  <Column field="Libelle" header="CODE" sortable>
                  </Column>
                  <Column header="TVA" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ Number(slotProps?.data?.ValeurTVA).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="HT" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ Number(slotProps?.data?.ValeurHT).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="TTC" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ Number(slotProps?.data?.ValeurTTC).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>

                </DataTable>
                <!-- <table class="table table-striped table-hovers caption-top">

                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">CODE </th>
                      <th class="text-start">TVA </th>
                      <th class="text-start">HT</th>
                      <th class="text-start">TTC </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(tva, indexTVA) in TransfertTVA">
                      <td class="text-start">
                        {{ tva?.Libelle }}
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurTVA?.toFixed(2)?.toString() }} €
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurHT?.toFixed(2)?.toString() }} €
                      </td>

                      <td class="text-start">
                        {{ tva?.ValeurTTC?.toFixed(2)?.toString() }} €
                      </td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
            <div v-if="arr_commandes?.length > 0 && concatCommandeTypes?.length > 0"
              class="col-12 col-md-12 col-xl-12  mb-3">
              <div class="table-responsive mb-5">
                <DataTable showGridlines stripedRows :value="concatCommandeTypes">
                  <Column field="Libelle" header="DESIGNATION" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Quantite).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        ({{ slotProps?.data?.OFFERT?.Libelle }})
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.QuantiteNet).toFixed(0) }}
                        {{ slotProps?.data?.Libelle }}
                        <span v-if="slotProps?.data?.ANNULATION">
                          ( {{ slotProps?.data?.ANNULATION?.Libelle }})
                        </span>
                      </span>
                    </template>
                  </Column>
                  <Column field="PrixUnitaireTTC" header="Prix UT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixUnitaireTTC).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column field="PrixTotalNetHT" header="Prix HT" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNetHT).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column field="PrixTotalNet" header="Total" sortable>
                    <template #body="slotProps">
                      <span v-if="slotProps?.data?.OFFERT">
                        {{ Number(slotProps?.data?.OFFERT?.Valeur).toFixed(2).toString() }} €
                      </span>
                      <span v-else>
                        {{ Number(slotProps?.data?.PrixTotalNet).toFixed(2).toString() }} €
                      </span>
                    </template>
                  </Column>
                  <Column header="Total" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.LibTVA }}
                      </span>
                    </template>
                  </Column>

                </DataTable>
                <!-- <table class="table table-striped table-hovers caption-top">
                  <thead class="table-dark">
                    <tr class="">
                      <th>DESIGNATION</th>
                      <th class="text-end">Prix UT</th>
                      <th class="text-end">Prix HT</th>
                      <th class="text-end">Total</th>
                      <th class="text-end">TVA</th>
                    </tr>
                  </thead>
                  <tbody v-if="CommandeArticles?.length > 0" class="">
                    <tr v-for="(article, index_article) in CommandeArticles" :key="index_article">
                      <td>
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Quantite).toFixed(0) }}
                          {{ article.Libelle }}
                          ({{ article?.OFFERT?.Libelle }})
                        </span>
                        <span v-else>
                          {{ Number(article.QuantiteNet).toFixed(0) }}
                          {{ article.Libelle }}
                          <span v-if="article?.ANNULATION">
                            ( {{ article?.ANNULATION?.Libelle }})
                          </span>
                        </span>

                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixUnitaireTTC).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNetHT).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                   {{ Number(article.PrixTotalNet).toFixed(2).toString() }} €
                  </span>
                  </td>
                  <td class="text-end">
                    {{ article.LibTVA }}
                  </td> 
                  </tr>
                  </tbody> 

                  <tbody v-if="CommandeTransfert?.length > 0" class="">
                    <tr v-for="(article, index_article) in CommandeTransfert" :key="index_article">
                      <td>
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Quantite).toFixed(0) }}
                          {{ article.Libelle }}
                          ({{ article?.OFFERT?.Libelle }})
                        </span>
                        <span v-else>
                          {{ Number(article.QuantiteNet).toFixed(0) }}
                          {{ article.Libelle }}
                          <span v-if="article?.ANNULATION">
                            ( {{ article?.ANNULATION?.Libelle }})
                          </span>
                        </span>

                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixUnitaireTTC).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNetHT).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        <span v-if="article?.OFFERT">
                          {{ Number(article?.OFFERT?.Valeur).toFixed(2).toString() }} €
                        </span>
                        <span v-else>
                          {{ Number(article.PrixTotalNet).toFixed(2).toString() }} €
                        </span>
                      </td>
                      <td class="text-end">
                        {{ article.LibTVA }}
                      </td>
                    </tr>
                  </tbody>
                </table>-->
              </div>
            </div>


            <!-- <div v-if="arr_statistiques" class="col-12 col-md-12 col-xl-12 mt-5 mb-3">
              <div class="table-responsive mb-5">
                <table class="table table-striped table-hovers caption-top">
                  <caption>
                    <code> STATISTIQUES : </code>
                  </caption>
                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">Libelle</th>
                      <th class="text-start">Famille</th>
                      <th class="text-start">Quantite</th>
                      <th class="text-start">Valeur</th>
                      <th class="text-start">Horodatage</th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(statistique, index_sts) in arr_statistiques" :key="index_sts">

                      <td class="text-start">
                        {{ statistique?.Libelle }}
                      </td>
                      <td class="text-start">
                        {{ statistique?.LibFamille }}
                      </td>
                      <td class="text-start">
                        {{ statistique?.Quantite }}
                      </td>
                      <td class="text-start">
                        {{ statistique?.Valeur.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ $dayjs(statistique?.Horodatage)?.utc().format("MM/DD/YYYY HH:mm:ss") }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div> -->
            <div v-if="arr_tvas?.length > 0" class="col-12 col-md-12 col-xl-12  mb-3">
              <div class="table-responsive mb-5">
                <div>
                  <strong> TVA : </strong>
                </div>
                <DataTable showGridlines stripedRows :value="arr_tvas">
                  <Column field="Libelle" header="Libelle" sortable> </Column>
                  <Column field="Horodatage" header="Horodatage" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ $dayjs(slotProps?.data?.Horodatage)?.utc().format("MM/DD/YYYY HH:mm:ss") }}
                      </span>
                    </template>
                  </Column>
                  <Column field="Taux" header="Taux %" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.Taux }} %
                    </template>
                  </Column>
                  <Column field="ValeurHT" header="HT" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurHT.toFixed(2).toString() }} €
                    </template>
                  </Column>
                  <Column field="ValeurTVA" header="TVA" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurTVA.toFixed(2).toString() }} €
                    </template>
                  </Column>
                  <Column field="TotalTTC" header="TTC" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurTTC.toFixed(2).toString() }} €
                    </template>
                  </Column>
                </DataTable>
                <!-- <table class="table table-striped table-hovers caption-top">

                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">Libelle</th>
                      <th class="text-start">Horodatage</th>
                      <th class="text-start">Taux %</th>
                      <th class="text-start">HT</th>
                      <th class="text-start">TVA </th>
                      <th class="text-start">TTC </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(tva, index_tva) in arr_tvas" :key="index_tva">

                      <td class="text-start">
                        {{ tva?.Libelle }}
                      </td>
                      <td class="text-start">
                        {{ $dayjs(tva?.Horodatage)?.utc().format("MM/DD/YYYY HH:mm:ss") }}
                      </td>
                      <td class="text-start">
                        {{ tva?.Taux }} %
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurHT.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurTVA.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ tva?.ValeurTTC.toFixed(2).toString() }} €
                      </td>

                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>

            <div v-if="arr_reglements?.length > 0" class="col-12 col-md-12 col-xl-12  mb-3">
              <div class="table-responsive mb-5">
                <div>
                  <strong> REGLEMENTS : </strong>
                </div>
                <DataTable showGridlines stripedRows :value="arr_reglements">
                  <Column field="LibFamille" header="Libelle" sortable> </Column>
                  <Column field="LibCaisse" header="LibCaisse" sortable>
                    <template #body="slotProps">
                      <span>
                        {{ slotProps?.data?.LibCaisse }}
                      </span>
                    </template>
                  </Column>
                  <Column field="ValeurUnitaire" header="Valeur Unitaire" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.ValeurUnitaire.toFixed(2).toString() }} €
                    </template>
                  </Column>
                  <Column field="TotalValue" header="Total Value" sortable>
                    <template #body="slotProps">
                      {{ slotProps?.data?.TotalValue.toFixed(2).toString() }} €
                    </template>
                  </Column>
                </DataTable>

                <!-- <table class="table table-striped table-hovers caption-top">
                  <caption>
                    <code> REGLEMENTS : </code>
                  </caption>
                  <thead class="table-dark">
                    <tr class="">
                      <th class="text-start">Libelle</th>
                      <th class="text-start">LibCaisse</th>
                      <th class="text-start">Valeur Unitaire</th>
                      <th class="text-start">Total Value </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr v-for="(reglement, index_reglement) in arr_reglements" :key="index_reglement">

                      <td class="text-start">
                        {{ reglement?.LibFamille }}
                      </td>
                      <td class="text-start">
                        {{ reglement?.LibCaisse }}
                      </td>
                      <td class="text-start">
                        {{ reglement?.ValeurUnitaire.toFixed(2).toString() }} €
                      </td>
                      <td class="text-start">
                        {{ reglement?.TotalValue.toFixed(2).toString() }} €
                      </td>

                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "mongoDATA",

  watch: {},
  data() {
    return {
      exportTo: {
        ticket: {
          columns: [],
          data: [],
          commandes: [],
          statistiques: [],
          reglements: [],
          transfertArticles: [],
          TransfertTVA: {},
          tvas: [],
          fileName: "",
        },
      },
      ticket_mongoDB: [],
      arr_commandes: [],
      arr_statistiques: [],
      arr_reglements: [],
      arr_tvas: [],
      CommandeArticles: [],
      CommandeTransfert: [],
      arr_transferts: [],
      transfertArticles: [],
      TransfertTVA: {},
      concatCommandeTypes: []
    };
  },
  mounted() {
    if (this.isAuthenticated && this.isRole == 'manager')
      this.Get_DetailsTickets();


  },
  created() {
    //this.interval = setInterval(() =>{
    //    this.getDateButton('fiveyear') },20000)
  },
  unmounted() {
    //clearInterval(this.interval)
  },
  methods: {



    ...mapActions(["getDetailsTicket", "getDetailsTickets", "getTransfertArticlesByGUUID"]),

    async Get_DetailsTickets() {
      const ticketIds = ["66e1a20858310d594d02376e", "66794b665b88c26ea503299d", "6672acdba1c10ba29689a71b", "66794b6a5b88c26ea50329b2"]
      this.exportTo.tickets = []; // Initialize the array to store all tickets' data
      this.TransfertTVA = {}; // Initialize the TransfertTVA object

      // Fetch all tickets at once
      let results = await this.getDetailsTickets({
        url: "entetes/details-tickets",
        ids: ticketIds,
      });
      this.ticket_mongoDB = results
      results.forEach(async (ticket) => {
        // Initialize arrays for each ticket
        let TransfertTVA = {};
        let arr_commandes = [];
        let arr_statistiques = [];
        let arr_reglements = [];
        let arr_tvas = [];
        let CommandeArticles = [];
        let CommandeTransfert = [];
        let concatCommandeTypes = [];
        let transfertArticles = []; // New array for transfert articles


        // Handle COMMANDE
        if (ticket?.COMMANDE) {
          if (!Array.isArray(ticket.COMMANDE)) {
            arr_commandes.push(ticket.COMMANDE);
          } else {
            arr_commandes.push(...ticket.COMMANDE);
          }
        }

        // Process Commande Articles and Transferts
        arr_commandes.forEach(commande => {
          if (commande?.ARTICLE) CommandeArticles.push(...commande.ARTICLE);
          if (commande?.TRANSFERT) CommandeTransfert.push(...commande.TRANSFERT);
        });
        concatCommandeTypes = [...CommandeArticles, ...CommandeTransfert];

        // Handle STATISTIQUES
        if (ticket?.STATISTIQUES) {
          if (!Array.isArray(ticket.STATISTIQUES)) {
            arr_statistiques.push(ticket.STATISTIQUES);
          } else {
            arr_statistiques.push(...ticket.STATISTIQUES);
          }
        }

        // Handle REGLEMENT
        if (ticket?.REGLEMENT) {
          if (!Array.isArray(ticket.REGLEMENT)) {
            arr_reglements.push(ticket.REGLEMENT);
          } else {
            arr_reglements.push(...ticket.REGLEMENT);
          }
        }

        // Handle TVA
        if (ticket?.TVA) {
          if (!Array.isArray(ticket.TVA)) {
            arr_tvas.push(ticket.TVA);
          } else {
            arr_tvas.push(...ticket.TVA);
          }
        }
        // Fetch transfert articles for the current ticket using the ticket's GUID
        let arr_transferts = await this.getTransfertArticlesByGUUID({
          url: "entetes/transfert-articles",
          _GUID: ticket.GUID,
        });

        // Store fetched transfert articles
        transfertArticles.push(...arr_transferts);

        // Initialize transfer values
        let ValeurTTC = 0, ValeurHT = 0, ValeurTVA = 0;

        // Process the transfert articles for TVA calculation
        transfertArticles.forEach(transfert => {
          transfert?.ARTICLE?.forEach(article => {
            if (article?.TVA) {
              const code = `${article.TVA.Numero} ${article.TVA.Libelle}`;
              if (!TransfertTVA[code]) {
                TransfertTVA[code] = {
                  Libelle: code,
                  ValeurTTC: article.TVA.ValeurTTC,
                  ValeurHT: article.TVA.ValeurHT,
                  ValeurTVA: article.TVA.ValeurTVA,
                };
              } else {
                TransfertTVA[code].ValeurTTC += article.TVA.ValeurTTC;
                TransfertTVA[code].ValeurHT += article.TVA.ValeurHT;
                TransfertTVA[code].ValeurTVA += article.TVA.ValeurTVA;
              }
            }
          });
        });

        // Calculate total TVA for the ticket and store it as 'Totaux'
        Object.values(TransfertTVA).forEach(data => {
          ValeurTTC += data.ValeurTTC;
          ValeurHT += data.ValeurHT;
          ValeurTVA += data.ValeurTVA;
        });

        // TransfertTVA.push({
        //   Libelle: 'Totaux',
        //   ValeurTTC,
        //   ValeurHT,
        //   ValeurTVA,
        // });

        // TransfertTVA['Totaux'] = {
        //   Libelle: 'Totaux',
        //   ValeurTTC,
        //   ValeurHT,
        //   ValeurTVA,
        // };

        // Store all data for the current ticket in the export array
        this.exportTo.tickets.push({
          data: ticket,
          arr_commandes,
          arr_statistiques,
          arr_reglements,
          arr_tvas,
          CommandeArticles,
          CommandeTransfert,
          concatCommandeTypes,
          transfertArticles, // Include transfertArticles in export
          TransfertTVA: TransfertTVA,
          totalTransferts: Math.abs(TransfertTVA['Totaux']?.ValeurTTC) || 0,

        });


      });


      this.exportTo.site = this.currentSite


    },



  },

  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),

    TotalPrice() {
      let Total = 0;
      if (this.arr_commandes) {
        Total = Object.values(this.arr_commandes).reduce((totalPrice, commande) => {
          var TotalPriceGroup = Object.values(commande.ARTICLE).reduce(
            (articlePrice, article) => {
              articlePrice = articlePrice + Number(article.PrixTotalNet);
              return articlePrice;
            },
            0
          );

          totalPrice = TotalPriceGroup + Number(totalPrice);
          return totalPrice;
        }, 0);
        return Total.toFixed(2);
      }
      return 0;
    },
  },
};
</script>
<!-- eslint-disable -->
<style>
.tableClasss {
  height: 400px;
  display: block;
  max-height: 400px;
  overflow: auto;
}

/* .tableClasss thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.tableClasss tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}


.info-restautant b {
    display: inline-block;
    width: 90px;
} */

.table thead th,
.datepicker table thead th {
  color: #fff !important;
  font-weight: bold;
}

.border-1-solid {
  border: 1px solid #d9d9d9;
}
</style>
