/*eslint-disable*/
import { createRouter, createWebHistory } from "vue-router";
import DashboardView from "../views/DashboardView.vue";
import ControltView from "../views/ControltView.vue";
import ReportView from "../views/ReportView.vue";
import StatsView from "../views/StatsView.vue";
import store from "../store";

import AbonnementExpirerView from "../views/AbonnementExpirerView.vue";

import WaitersView from "../views/Waiter/WaitersView.vue";
import AddwaiterView from "../views/Waiter/AddwaiterView.vue";
import EditWaiterView from "../views/Waiter/EditWaiterView.vue";

import AdminsView from "../views/Admin/AdminsView.vue";
import AddAdminView from "../views/Admin/AddAdminView.vue";
import EditAdminView from "../views/Admin/EditAdminView.vue";

import ManagersView from "../views/Manager/ManagersView.vue";
import AddmanagerView from "../views/Manager/AddmanagerView.vue";
import EditManagerView from "../views/Manager/EditManagerView.vue";
import AffecteRestaurantsView from "../views/Manager/AffecteRestaurantsView.vue";
import SubscriptionsCsiCodes from "../views/Manager/SubscriptionsCsiCodes.vue";
import SubscriptionsPaid from "../views/Manager/SubscriptionsPaid.vue";

import RenouvelerAbonnementView from "../views/Restaurants/RenouvelerAbonnementView.vue";
import ResilierAbonnementView from "../views/Restaurants/ResilierAbonnementView.vue";
import AddrestaurantView from "../views/Restaurants/AddrestaurantView.vue";
import RestaurantView from "../views/Restaurants/RestaurantView.vue";
import EditRestaurantView from "../views/Restaurants/EditRestaurantView.vue";
import DetailsRestaurant from "../views/Restaurants/DetailsRestaurant.vue";

import ProfileView from "../views/Profile/ProfileView.vue";
import EditprofileView from "../views/Profile/EditprofileView.vue";
import EditpasswordView from "../views/Profile/EditpasswordView.vue";
import LoginView from "../views/LoginView.vue";
import RegisterView from "../views/RegisterView.vue";
import PageNotFound from "../views/PageNotFoundView.vue";

import Parametrages from "../views/Parametrages/index.vue";

//by ayoub
import Tickets from "../views/tickets/TicketIndex.vue";
import detailsTicket from "../views/tickets/DetailsTicket.vue";
import detailsTickets from "../views/tickets/DetailsTickets.vue";

//import TicketsBaliseName from "../views/ticketsByBaliseName/index.vue";
//import OperationdetailsTicket from "../views/ticketsByBaliseName/details_ticket.vue";

import Dashboard from "../views/dashboard/DashboardIndex.vue";
import ChiffreAffaires from "../views/dashboard/ChiffreAffaires.vue";

import Vendeurs from "../views/vendeurs/VendeursIndex.vue";
import Pointeuses from "../views/vendeurs/PointeusesIndex.vue";
import Caisses from "../views/statistiqueCaisses/CaissesIndex.vue";

import Reports from "../views/reports/ReportIndex.vue";


/***Abonner */
import AbonnerRestaurant from "../views/Abonner/AbonnerRestaurant.vue";
import payementSuccess from "../views/Abonner/payementSuccess.vue";
import payementCancled from "../views/Abonner/payementCancled.vue";
import RenouvelerAbonnement from "../views/Abonner/RenouvelerAbonnement.vue";
import DetailsRestaurantManager from "../views/Abonner/DetailsRestaurant.vue";
import ModifierRestaurantManager from "../views/Abonner/EditRestaurantView.vue";
import ListRestaurantsView from "../views/Abonner/RestaurantView.vue";

import AbonnementsView from "../views/Abonnements/AbonnementsView.vue";
import CodecsisView from "../views/Codecsis/CodecsisView.vue";

import ComptesView from "../views/Comptes/ComptesView.vue";
import TicketsCompteView from "../views/Comptes/TicketsCompteView.vue";


const BASE_ROUTE = process.env.VUE_APP_BASE_ROUTE || "/";

const routes = [
  {
    path: "/dashboard",
    name: "dashboard-1",
    component: DashboardView,
    meta: { requiresAuth: true, roles: ['manager'] },
  },
  /*** routes by ayb */
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: { isComponentDates: true, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/chiffre-affaires",
    name: "chiffre-affaires",
    component: ChiffreAffaires,
    meta: { isComponentDates: false, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/vendeurs",
    name: "vendeurs",
    component: Vendeurs,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/pointeuse",
    name: "pointeuse",
    component: Pointeuses,
    meta: { isComponentDates: false, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/statistiques",
    name: "statistiques",
    component: Caisses,
    meta: { isComponentDates: true, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/tickets",
    name: "tickets",
    component: Tickets,
    meta: { isComponentDates: true, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/detailsticket/:id",
    name: "detailsticket",
    component: detailsTicket,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/detailstickets",
    name: "detailstickets",
    component: detailsTickets,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  // {
  //   path: "/tickets-balise",
  //   name: "tickets-balise",
  //   component: TicketsBaliseName,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/balise-detailsticket/:id/:operation",
  //   name: "balise-detailsticket",
  //   component: OperationdetailsTicket,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: { isComponentDates: true, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  /****end routes by ayb */
  {
    path: "/control",
    name: "control",
    component: ControltView,
    meta: { isComponentDates: true, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/report",
    name: "report",
    component: ReportView,
    meta: { isComponentDates: true, requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/stats",
    name: "stats",
    component: StatsView,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/waiters",
    name: "waiters",
    component: WaitersView,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/addwaiter",
    name: "addwaiter",
    component: AddwaiterView,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/managers",
    name: "managers",
    component: ManagersView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/addmanager",
    name: "addmanager",
    component: AddmanagerView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/admins",
    name: "admins",
    component: AdminsView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/addadmin",
    name: "addadmin",
    component: AddAdminView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/restaurants",
    name: "restaurants",
    component: RestaurantView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/details-restaurant/:id",
    name: "details-restaurant",
    component: DetailsRestaurant,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/addrestaurant",
    name: "addrestaurant",
    component: AddrestaurantView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/creation-restaurant",
    name: "creation-restaurant",
    component: AbonnerRestaurant,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: [] },
  },
  {
    path: "/renouveler-abonnement/:id",
    name: "renouveler-abonnement",
    component: RenouvelerAbonnementView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },

  {
    path: "/resilier-abonnement/:id",
    name: "resilier-abonnement",
    component: ResilierAbonnementView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },

  {
    path: "/modifier-restaurant/:id",
    name: "modifier-restaurant",
    component: EditRestaurantView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: { requiresAuth: true, withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/editprofile",
    name: "editprofile",
    component: EditprofileView,
    meta: { requiresAuth: true, withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/editpassword",
    name: "editpassword",
    component: EditpasswordView,
    meta: { requiresAuth: true, withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/editmanager",
    name: "editmanager",
    component: EditManagerView,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/affecte-restaurants",
    name: "affecte-restaurants",
    component: AffecteRestaurantsView,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/subscriptions-csicodes",
    name: "subscriptions-csicodes",
    component: SubscriptionsCsiCodes,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/subscriptions-paid",
    name: "subscriptions-paid",
    component: SubscriptionsPaid,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/editadmin",
    name: "editadmin",
    component: EditAdminView,
    meta: { requiresAuth: true, roles: ['super-admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/editwaiter",
    name: "editwaiter",
    component: EditWaiterView,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },

  {
    path: "/abonnement-expirer",
    name: "abonnement-expirer",
    component: AbonnementExpirerView,
    meta: { requiresAuth: true, roles: ['super-admin', 'admin', 'manager'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },

  // {
  //   path: "/parametres",
  //   name: "parametres",
  //   component: ParametresView,
  //   meta: { requiresAuth: true, roles: ['super-admin', 'admin'] },
  // },
  // {
  //   path: "/addparametre",
  //   name: "addparametre",
  //   component: AddParametreView,
  //   meta: { requiresAuth: true, roles: ['manager'] },
  // },
  // {
  //   path: "/editparametre",
  //   name: "editparametre",
  //   component: EditParametreView,
  //   meta: { requiresAuth: true, roles: ['manager'] },
  // },
  {
    path: "/parametrages",
    name: "parametrages",
    component: Parametrages,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/payement-success/:token",
    name: "payement-success",
    component: payementSuccess,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: [] },
  },
  {
    path: "/payement-cancled/:token",
    name: "payement-cancled",
    component: payementCancled,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: [] },
  },
  {
    path: "/renouveler",
    name: "renouveler",
    component: RenouvelerAbonnement,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: { guest: true, roles: [], withComponents: [] },
  },
  {
    path: "/register",
    name: "register",
    component: RegisterView,
    meta: { requiresAuth: false, guest: true, roles: [], withComponents: [] },
  },
  {
    path: "/abonnements",
    name: "Abonnements",
    component: AbonnementsView,
    meta: { requiresAuth: true, roles: ['super-admin', 'manager'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/codecsi",
    name: "Codecsi",
    component: CodecsisView,
    meta: { requiresAuth: true, roles: ['super-admin', 'manager'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },

  {
    path: "/list-restaurants",
    name: "list-restaurants",
    component: ListRestaurantsView,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/details-restaurant-manager/:id",
    name: "details-restaurant-manager",
    component: DetailsRestaurantManager,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/modifier-restaurant-manager/:id",
    name: "modifier-restaurant-manager",
    component: ModifierRestaurantManager,
    meta: { requiresAuth: true, roles: ['manager'], withComponents: ['navbar', 'footer', 'sidebar', 'WelcomeToReporting', 'AbonnementExpirerView'] },
  },
  {
    path: "/comptes",
    name: "comptes",
    component: ComptesView,
    meta: { requiresAuth: true, roles: ['super-admin', 'manager'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/tickets-compte/:id",
    name: "tickets-compte",
    component: TicketsCompteView,
    meta: { isComponentDates: false, requiresAuth: true, roles: ['super-admin', 'manager'], withComponents: ['navbar', 'footer', 'sidebar'] },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: PageNotFound,
    meta: { requiresAuth: true, withComponents: ['navbar', 'footer', 'sidebar'] },
  },
];

const router = createRouter({
  //base: BASE_ROUTE,
  mode: 'history',
  history: createWebHistory(),
  routes,
});


// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
//   if (requiresAuth && !store.getters.isRole) {
//     next('/login')
//   } else if (requiresAuth && store.getters.isRole) {
//     const requiredRoles = to.meta.roles
//     if (requiredRoles && !requiredRoles.some(role => store.getters.isRole.includes(role))) {
//       if (store.getters.isRole == 'admin' || store.getters.isRole == 'super-admin')
//         next('/restaurants')
//       else
//         next('/')
//     }
//     else {
//       if (store.getters.isRole == 'manager' && !store.getters.StateIsAbonnementActif) {
//         if (to.name == 'creation-restaurant' || to.name == 'renouveler' || to.name == 'payement-success' || to.name == 'payement-cancled') {
//           next()
//         }
//         else {
//           next('/abonnement-expirer')
//         }
//         return
//       } else {
//         next()
//       }
//     }
//   }
//   else {
//     next()
//   }
// })


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const userRole = store.getters.isRole
  if (requiresAuth && !userRole) {
    next('/login')
    return
  }
  if (requiresAuth) {
    const requiredRoles = to.meta.roles
    const isAdmin = userRole === 'admin' || userRole === 'super-admin'
    const isManager = userRole === 'manager'
    if (requiredRoles && !requiredRoles.some(role => userRole.includes(role))) {
      next(isAdmin ? '/managers' : '/')
    } else if (isManager && !store.getters.StateIsAbonnementActif) {
      const pages = ['creation-restaurant', 'list-restaurants', 'renouveler', 'payement-success', 'details-restaurant-manager', 'modifier-restaurant-manager', 'payement-cancled']
      if (pages.includes(to.name) || to.path === '/') {
        next()
      } else {
        next('/')
        // next('/abonnement-expirer')
      }
    } else {
      next()
    }
  } else {
    next()
  }
})


// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (store.getters.isAuthenticated) {
//       next();
//       return;
//     }
//     next("/login");
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.guest)) {
//     if (store.getters.isAuthenticated) {
//       next("/");
//       return;
//     }
//     next();
//   } else {
//     next();
//   }
// });

export default router;
