<!-- eslint-disable -->
<script setup>
import { ref } from 'vue';
const expandedReportRows = ref([]);
</script>
<!-- eslint-disable -->
<!-- eslint-disable -->
<template>
  <div class=" " v-if="show">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-12 mb-4 d-flex justify-content-between align-items-center">
        <div class="noble-ui-logo d-block mb-2">
          Liste<span>&nbsp;des comptes</span>
        </div>
      </div>
    </div>
    <div class="row w-100 mx-0 auth-page mb-5 usercard p-3">
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="Titre" class="form-label">Genre</label>
            <select v-model="search.Titre" class="form-control">
              <option :value="null">
                Tous
              </option>
              <option value="M.">
                Mr
              </option>
              <option value="Mme.">
                Mme
              </option>
              <option value="STE">
                STE
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="Nom" class="form-label">Nom</label>
            <input type="text" class="form-control" id="Nom" placeholder="Nom" v-model="search.Nom" />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="mb-3 w-100">
            <button type="button" class="float-end btn btn-primary w-33" @click="fetchContacts">
              Recherche
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card ">
          <div class="card-header">
            <h3 class="card-title text-left"> Comptes </h3>
          </div>
          <div v-if="contacts.length > 0" class="card-body">
            <div class="table-responsive tableClasss">
              <table class="table table-striped table-hovers caption-top">
                <thead class="table-dark">
                  <tr class="text-left">
                    <!-- <th>S/H</th> -->
                    <th>Nom</th>
                    <th>ADRESSE</th>
                    <th>Pays</th>
                    <th>Télephone</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-for="(contact, indexCompte) in contacts  " :key="`report-${indexCompte}`">
                  <tr>
                    <!-- <td class="cursor-pointer" @click="
                      ShowHide(
                        indexCompte,
                        'report-icon-show-',
                        'report-icon-hide-',
                        'report-lib-famille-'
                      )
                      ">
                      <span class="" :id="`report-icon-hide-${indexCompte}`">
                        <i class="fas fa-chevron-right"></i>
                      </span>
                      <span class="hide" :id="`report-icon-show-${indexCompte}`">
                        <i class="fas fa-chevron-down"></i>
                      </span>
                    </td> -->
                    <td>
                      {{ contact.Titre + ' ' + contact.Nom }}
                    </td>
                    <td>
                      {{ contact?.SITUATION?.ADRESSE }}
                    </td>
                    <td>
                      <span v-if="contact?.PAYS">
                        {{ contact?.PAYS + ' ' }}
                      </span>
                      <span v-if="contact?.SITUATION?.VILLE">
                        {{ contact?.SITUATION?.VILLE + ' ' }}
                      </span>
                      <span v-if="contact?.SITUATION?.CODEPOSTAL">
                        {{ contact?.SITUATION?.CODEPOSTAL + ' ' }}
                      </span>
                    </td>
                    <td>
                      {{ contact?.TELEPHONE }}
                    </td>
                    <td>
                      <router-link class="btn btn-info btn-icon-text mx-2 mb-3 mb-md-0"
                        :to="{ name: 'tickets-compte', params: { id: contact?._id } }">
                        Voir
                      </router-link>
                    </td>
                  </tr>
                  <!-- <tr :id="`report-lib-famille-${indexCompte}`" class="childTableRow">
                    <td colspan="5">
                      <table class="table">
                        <thead class="table-dark">
                          <tr class="text-left">
                            <th>Site</th>
                            <th>N°Ticket</th>
                            <th>Operation</th>
                            <th>Date</th>
                            <th>Montant HT</th>
                            <th>Montant TTC</th>
                            <th>Montant TVA</th>
                            <th>Voir</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(entete, indexEntete) in contact?.entete?.filter((ent) => contact?.TransfereVersUUIDs?.includes(ent?.TransfereVersUUID))"
                            :key="`libfamille-${indexEntete}`">
                            <td>{{ entete?.LibSite }}</td>
                            <td>Ticket N°{{ entete?.NumTicket }}</td>
                            <td>{{ entete?.Operation }}</td>

                            <td>
                              {{ $dayjs(entete?.Horodatage)?.utc().format("MM/DD/YYYY HH:mm:ss") }}
                            </td>
                            <td>{{ (entete?.MontantHT)?.toFixed(2) }} €</td>
                            <td>{{ entete?.MontantTTC?.toFixed(2) }} €</td>
                            <td>{{ entete?.MontantTVA?.toFixed(2) }} €</td>
                            <td>
                              <router-link class="btn btn-info btn-icon-text mx-2 mb-3 mb-md-0"
                                :to="{ name: 'detailsticket', params: { id: entete?._id } }">
                                Voir
                              </router-link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr> -->

                </tbody>
              </table>
            </div>
          </div>

          <div v-else class="card-body">
            <datanotfound :text="$t(`content.message.datanotfound`)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", User: "StateUser", BaseUrl: "StateBase", currentSite: "currentSite", }),
  },
  mounted() {
    const get = async () => {
      try {
        this.fetchContacts()
        this.show = true;
      } catch (error) {
        if (error) {
          this.show = false;
          console.log(error);
          this.showAlert("Forbidden resource");
        }
      }
    };
    if (this.isAuthenticated && this.$store.state.roles.includes(this.isRole))
      get();
  },
  data() {
    return {
      contacts: [],
      show: false,
      isModalOpen: false,
      search: {
        Nom: "",
        Titre: null,
        SITE_ID: null,
      },


    };
  },
  methods: {
    ...mapActions([
      "searchContacts",
    ]),
    toggleDetails(contactId) {
      const contact = this.contacts.find(contact => contact?._id === contactId);
      if (contact) {
        contact.showDetails = !contact.showDetails;
      }
    },
    async fetchContacts() {
      const searchData = {}
      if (this.search?.Titre)
        searchData['Titre'] = this.search?.Titre
      if (this.search?.Nom)
        searchData['Nom'] = this.search?.Nom
      if (this.currentSite?._id)
        searchData['SITE_ID'] = this.currentSite?._id

      this.contacts = await this.searchContacts(searchData);
    },
    ShowHide(index, iconshow, iconhide, libelle) {
      var ele_arr_down = document.getElementById(iconshow + index);
      var ele_arr_right = document.getElementById(iconhide + index);
      var ele_sub_table = document.getElementById(libelle + index);
      ele_sub_table.classList.toggle("show");
      ele_arr_down.classList.toggle("hide");
      ele_arr_right.classList.toggle("hide");
    },
  },
};
</script>
<!-- eslint-disable -->
<style>
.abn-disabled {
  background-color: #f69a9a;
}

.hide {
  display: none;
}
</style>
