/*eslint-disable*/
import { Division, alertSwal } from "./globale.js";

import { createApp } from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { utils, write, writeFile, WorkBook } from 'xlsx';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import moment from "moment";
import dayjs from "dayjs";


const app = createApp({});
app.use(VueSweetalert2);


export function exportToPdfMail(exportTo) {

    var doc = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        precision: 20,
    });

    let startX1 = 5;

    let startY = 5;
    doc.setFont("helvetica", "normal", "bold");
    doc.setFontSize(13);
    var pageFullWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth(); //60; /
    var pageWidth = pageFullWidth - 20;
    let startX2 = 40;
    let START_X_COL1 = 10;
    let START_X_COL2 = 25;

    let START_TEXT_POS_X_CENTER = pageFullWidth / 2;
    startY += 0;
    doc.setFont("helvetica", "normal", "bold");
    doc.setFontSize(12);
    doc.text(exportTo.data.LibSite, START_TEXT_POS_X_CENTER, startY, { align: "center" });

    startY += 5;
    doc.setFont("helvetica", "normal", "normal");
    doc.setFontSize(9);
    doc.text(exportTo.data.EmetteurSociete, START_TEXT_POS_X_CENTER, startY, { align: "center" });

    if (exportTo.data.EmetteurAdresse) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(exportTo.data.EmetteurAdresse, START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurCodePostal) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(exportTo.data.EmetteurCodePostal.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurVille) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(exportTo.data.EmetteurVille.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurPays) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(exportTo.data.EmetteurPays.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurTelephone) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(exportTo.data.EmetteurTelephone.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurCodeNAF) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(exportTo.data.EmetteurCodeNAF.toString() + ' ' + exportTo.data.EmetteurNumTVA.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurSiret) {
        startY += 4;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text('SIRET ' + exportTo.data.EmetteurSiret.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.TableNumber) {
        startY += 7;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(9);
        doc.text("TABLE", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.TableNumber.toString(), START_X_COL2, startY, { align: "left" });
    }
    if (exportTo.data.NbCouverts) {
        startY += 5;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(7);
        doc.text("COUVERTS", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(7);
        doc.text(" : " + exportTo.data.NbCouverts.toString(), START_X_COL2, startY, { align: "left" });

        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(7);
        doc.text("COUVERT MOYEN", pageWidth - 25, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(7);
        doc.text(" : " + (exportTo.data.MontantTTC / exportTo.data.NbCouverts).toFixed(2), pageWidth, startY, { align: "left" });
    }

    if (exportTo.data.Horodatage) {
        moment.locale("fr");
        startY += 7;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(moment(exportTo.data.Horodatage).utc().format(
            "dddd DD MMMM YYYY HH:mm:ss"
        ), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.NumTicket) {
        startY += 5;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(9);
        doc.text("TICKET : " + exportTo.data.NumTicket.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }


    startY += 5
    let finalY = startY + 6

    // this for transfert
    if (exportTo['transfertArticles']?.length > 0) {

        /**title */
        startY += 5
        // Draw the line
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY, pageWidth + 10, startY);
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY + 0.5, pageWidth + 10, startY + 0.5);

        startY += 5;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(9);
        doc.text(
            "TOTAL TRANSFERE : " + (exportTo['totalTransferts']).toFixed(2) + " €",
            START_TEXT_POS_X_CENTER, startY, { align: "center" }
        );
        startY += 3
        // Draw the line
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY, pageWidth + 10, startY);
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY + 0.5, pageWidth + 10, startY + 0.5);
        /**end title */

        finalY = startY + 2;
        var element = [];
        exportTo['transfertArticles']?.map((article) => {
            var Libelle = ''
            var TotalNet = ''
            var UnitaireTTC = ''
            var NetHT = ''
            //el?.ARTICLE?.map((article) => {
            if (article?.OFFERT) {
                Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                    UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
            } else {
                Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
            }
            element.push([
                Libelle,
                UnitaireTTC,
                NetHT,
                TotalNet
            ]);
            //  });
            const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
            const tableWidth = pageWidth; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                columnStyles[i].halign = 'left';

                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };
            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                margin: { left: START_X_COL1 },
                headStyles: {
                    fontSize: 6
                }
            };
            doc.autoTable({
                margin: { top: 10 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element, // [el.GUID], //.slice(1),
                ...options, // Spread the options object
            });
        });
    }
    /**tva transfert */
    /*** THIS FOR TVA */
    if (Object.keys(exportTo['TransfertTVA']).length > 0) {
        finalY = doc.autoTable.previous.finalY + 2
        //const TVA = (Array.isArray(exportTo?.data?.TVA)) ? exportTo.data.TVA : [exportTo.data.TVA]
        var element = [];
        Object.entries(exportTo['TransfertTVA']).forEach(([code, el]) => {
            // exportTo['TransfertTVA']?.map((el) => {
            // element[el.GUID] = [];
            // el.ARTICLE.map((article) => {
            element.push([
                el?.Libelle,
                Number(el?.ValeurTVA).toFixed(2).toString(),
                Number(el?.ValeurHT).toFixed(2).toString(),
                Number(el?.ValeurTTC).toFixed(2).toString()
            ]);
            //}); 

            const cols = ["CODE", "TVA", "HT", "TTC"]
            const tableWidth = pageWidth; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };

            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                margin: { left: START_X_COL1 },
                headStyles: {
                    fontSize: 6
                }

            };
            doc.autoTable({
                margin: { top: 10 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element,
                ...options, // Spread the options object
            });
        });

        finalY = doc.autoTable.previous.finalY + 6
        startY = finalY;
        // doc.setDrawColor(0, 0, 0);
        // doc.line(0, startY, pageWidth, startY);
        finalY = finalY + 6
    }

    if (exportTo['commandes']?.length > 0) {
        /** title */
        // Draw the line
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY, pageWidth + 10, startY);
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY + 0.5, pageWidth + 10, startY + 0.5);

        startY += 5;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(9);
        doc.text(
            "TOTAL TTC : " + (exportTo.data.MontantTTC).toFixed(2) + " €",
            START_TEXT_POS_X_CENTER, startY, { align: "center" }
        );
        startY += 3
        // Draw the line
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY, pageWidth + 10, startY);
        doc.setDrawColor(0, 0, 0);
        doc.line(START_X_COL1, startY + 0.5, pageWidth + 10, startY + 0.5);
        /**end  title */
        finalY = finalY + 6
        var element = [];
        exportTo['commandes']?.map((el) => {
            var Libelle = ''
            var NetHT = ''
            var UnitaireTTC = ''
            var TotalNet = ''
            el?.ARTICLE?.map((article) => {
                if (article?.OFFERT) {
                    Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                    NetHT = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                        UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                    TotalNet = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                } else {
                    Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                    NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
                    UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                    TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                }

                element.push([
                    Libelle,
                    UnitaireTTC,
                    NetHT,
                    TotalNet
                ]);
            });


            const cols = ["DESIGNATION", "PU", "Net HT (€)", "Total Net (€)"]
            const tableWidth = pageWidth; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                columnStyles[i].halign = 'left';

                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };
            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                margin: { left: 10 },
                headStyles: {
                    fontSize: 6
                }
            };
            doc.autoTable({
                margin: { top: 10 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element, //.slice(1),
                ...options, // Spread the options object
            });
        });
    }
    /*finalY = doc.autoTable.previous.finalY + 10
    startY = finalY;
    doc.setFont("helvetica", "normal", "bold");
    doc.setFontSize(8);
    doc.text("STATISTIQUES", START_X_COL1, startY, "left");
    startY = finalY + 4;
    if (exportTo['statistiques']?.length > 0) {
        var element = [];
        exportTo['statistiques']?.map((el) => {

            element.push([
                el.LibCaisse,
                el.Libelle,
                Number(el.Quantite).toFixed(0).toString(),
                Number(el.Valeur).toFixed(2).toString(),

            ]);
            const cols = ["CAISSE", "LIBELLE", "QUANTITE", "VALUER €"]
            const tableWidth = pageWidth; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                columnStyles[i].halign = 'left';

                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };
            console.log(columnStyles)
            const options = {
                startY: startY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                margin: { left: 10 },
                headStyles: {
                    fontSize: 6
                }
            };
            doc.autoTable({
                margin: { top: 10 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element, //.slice(1),
                ...options, // Spread the options object
            });
        });
    }*/


    finalY = doc.autoTable.previous.finalY + 6
    startY = finalY;
    startX1 = pageWidth - 40;
    startX2 = pageWidth - 2;

    doc.setFont("helvetica", "normal", "bold");
    doc.setFontSize(9);
    doc.text(
        "TOTAL TTC : " + (exportTo.data.MontantTTC).toFixed(2) + " €",
        pageWidth,
        startY,
        "right"
    );

    if (exportTo.data.LigneCompte) {
        startY += 9;
        doc.setFont("helvetica", "normal", "bold");
        doc.setFontSize(8);
        doc.text("NOMBRE LIGNES ARTICLES", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(8);
        doc.text(" : " + exportTo.data.LigneCompte.toString(), START_X_COL2 + 24, startY, { align: "left" });
    }

    finalY = startY + 4

    /*** THIS FOR TVA */
    const TVA = (Array.isArray(exportTo.data.TVA)) ? exportTo.data.TVA : [exportTo.data.TVA]
    var element = [];
    TVA.map((el) => {
        // element[el.GUID] = [];
        // el.ARTICLE.map((article) => {
        element.push([
            el.Libelle,
            Number(el.ValeurTVA).toFixed(2).toString(),
            Number(el.ValeurHT).toFixed(2).toString(),
            Number(el.ValeurTTC).toFixed(2).toString()
        ]);
        //}); 

        const cols = ["CODE", "TVA", "HT", "TTC"]
        const tableWidth = pageWidth; // Total width of the table
        const columnCount = cols.length; // Assuming all rows have the same number of columns
        const columnWidth = tableWidth / columnCount;
        const FirstcellWidth = columnWidth + 15
        const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
        // Set the starting position of the table
        // Set the starting position of the table
        const startXX = 20;
        let currentX = startXX;

        // Calculate the column widths and adjust the X-coordinate of each cell
        const columnStyles = {};
        for (let i = 0; i < columnCount; i++) {
            const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
            columnStyles[i] = { cellWidth: cellWidth };
            columnStyles[i].cellX = currentX;
            currentX += cellWidth;
        }
        const styles = {
            fontSize: 7, // Font size for the table body
            cellWidth: 'auto', // Allow the column width to adjust automatically
            overflow: 'linebreak', // Enable text wrapping within cells
        };

        const options = {
            startY: finalY, // Adjust the vertical position of the table
            columnStyles: columnStyles, // Set column widths
            styles: styles, // Set font sizes
            overflow: 'linebreak', // Enable automatic table resizing with text wrapping
            margin: { left: 10 },
            headStyles: {
                fontSize: 6
            }

        };
        doc.autoTable({
            margin: { top: 10 },
            pageBreak: "auto",
            theme: "plain",
            headStyles: {
                fillColor: [29, 30, 34],
                textColor: [255, 255, 255],
                cellPadding: 3,
            },
            footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
            bodyStyles: { cellPadding: 1 },
            head: [cols],
            body: element,
            ...options, // Spread the options object
        });
    });




    finalY = doc.autoTable.previous.finalY + 14
    startY = finalY;
    startX1 = 10;
    startX2 = pageWidth;
    // Draw the line
    //startY +=5
    START_X_COL1 = 10
    START_X_COL2 = START_X_COL2 + 10
    doc.setFont("helvetica", "normal", "normal");
    doc.setFontSize(9);
    doc.text("TOTAL", startX1, startY - 2, "left");
    doc.setFont("helvetica", "normal", "normal");
    doc.setFontSize(9);
    doc.text(" : " + (exportTo.data.MontantTTC).toFixed(2).toString(), startX2, startY - 2, "right");

    doc.setDrawColor(0, 0, 0);
    doc.line(10, startY, pageWidth + 10, startY);

    if (exportTo.data.LibCaisse) {
        startY += 8;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text("CAISSE", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.LibCaisse.toString(), START_X_COL2, startY, { align: "left" });
    }
    if (exportTo.data.Horodatage) {
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(moment(exportTo.data.Horodatage).utc().format(
            "DD/MM/YYYY HH:mm:ss"
        ),
            pageWidth,
            startY,
            "right"
        );
    }

    if (exportTo.data.LibVendeur) {
        startY += 5;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text("VENDEUR", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.LibVendeur.toString(), START_X_COL2, startY, { align: "left" });
    }

    if (exportTo.data.LibVendeur) {
        startY += 5;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text("VENDEUR", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.LibVendeur.toString(), START_X_COL2, startY, { align: "left" });
    }

    if (exportTo.data.LibVendeur) {
        startY += 12;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text("NF525) B00204DH5", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.Operation.toString(), START_X_COL2 + 10, startY, { align: "left" });
    }
    if (exportTo.data.NumTicket) {
        startY += 8;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text("TICKET", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.NumTicket.toString(), START_X_COL2, startY, { align: "left" });
    }
    if (exportTo.data.VersionCaisse) {
        startY += 5;
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text("VERSION", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + exportTo.data.VersionCaisse.toString(), START_X_COL2, startY, { align: "left" });
    }

    if (exportTo.data.VersionCaisse) {
        startY += 5;
        doc.setFont("helvetica", "italic", "normal");
        doc.setFontSize(9);
        doc.text("Nb Réimpression", START_X_COL1, startY, "left");
        doc.setFont("helvetica", "normal", "normal");
        doc.setFontSize(9);
        doc.text(" : " + "0", START_X_COL2 + 8, startY, { align: "left" });
    }

    const base64PDF = doc.output('dataurlstring');
    const pdfBuffer = base64PDF.split('base64,')[1]
    return pdfBuffer

    // doc.save(exportTo.fileName);

}







export function exportToExcel(data, filename, is_multi_tables, sendtomail) {

    // Create a new workbook
    const workbook = {
        Sheets: {},
        SheetNames: [],
    };
    if (is_multi_tables) {
        Object.keys(data).map(key => {
            const dataArray = data[key];
            workbook.Sheets[key] = utils.json_to_sheet(dataArray);
            workbook.SheetNames.push(key);
        });

        if (sendtomail) {
            // Save the workbook as an Excel file
            const excelBuffer = write(workbook, {
                bookType: 'xlsx',
                type: 'base64', // Use base64 encoding
                mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            return excelBuffer;
        } else {
            writeFile(workbook, filename + '.xlsx');
            return false
        }


    } else {
        if (data?.length > 0) {

            const worksheet = utils.json_to_sheet(data);
            workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, 'Sheet 1');

            if (sendtomail) {
                const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array', mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                return excelBuffer
            } else {

                writeFile(workbook, filename + '.xlsx');
                return false
            }
        }
    }
    alertSwal("warning", "Il n'y a pas de données disponibles")
}




export function exportToPdf(exportTo, sendtomail) {
    console.log(exportTo)
    var doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: [80, 200]
    });

    let startX1 = 5;

    let startY = 5;

    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth(); //60; /
    const pageHeight = doc.internal.pageSize.getHeight(); // Get the height of one page

    function addDashedLine(yPosition, pageWidth) {
        doc.setFont("Courier", "normal", "normal");
        doc.setFontSize(6);
        const dashWidth = doc.getTextWidth('-'); // Width of one dash
        const totalDashes = Math.floor(pageWidth / dashWidth); // Calculate how many dashes fit
        const dashedLine = '-'.repeat(totalDashes); // Create the line
        doc.text(dashedLine, 0, yPosition);
    }


    function addNewHeight() {
        // Check if we exceed the page height
        if (startY + 10 > pageHeight) {
            doc.addPage([80, 200]); // Add a new page if needed
            startY = 10; // Reset Y position for the new page
        }
    }

    let startX2 = 40;
    let START_X_COL1 = 1;
    let START_X_COL2 = 15;

    let START_TEXT_POS_X_CENTER = pageWidth / 2;
    startY += 0;
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(10);
    doc.text(exportTo.data.LibSite, START_TEXT_POS_X_CENTER, startY, { align: "center" });

    startY += 5;
    doc.setFont("Courier", "normal", "normal");
    doc.setFontSize(7);
    doc.text(exportTo.data.EmetteurSociete, START_TEXT_POS_X_CENTER, startY, { align: "center" });

    if (exportTo.data.EmetteurAdresse) {
        startY += 4;

        doc.text(exportTo.data.EmetteurAdresse, START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurCodePostal) {
        startY += 4;

        doc.text(exportTo.data.EmetteurCodePostal.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurVille) {
        startY += 4;
        doc.text(exportTo.data.EmetteurVille.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurPays) {
        startY += 4;
        doc.text(exportTo.data.EmetteurPays.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurTelephone) {
        startY += 4;
        doc.text(exportTo.data.EmetteurTelephone.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurCodeNAF) {
        startY += 4;
        doc.text(exportTo.data.EmetteurCodeNAF.toString() + ' ' + exportTo.data.EmetteurNumTVA.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.EmetteurSiret) {
        startY += 4;
        doc.text('SIRET ' + exportTo.data.EmetteurSiret.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    startY += 4;
    addDashedLine(startY, pageWidth)
    addNewHeight()



    if (exportTo.data.TableNumber) {
        startY += 7;

        doc.text("TABLE", START_X_COL1, startY, "left");
        doc.text(" : " + exportTo.data.TableNumber.toString(), START_X_COL2, startY, { align: "left" });
    }
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(6);
    if (exportTo.data.NbCouverts) {
        startY += 5;

        doc.text("COUVERTS", START_X_COL1, startY, "left");
        doc.text(" : " + exportTo.data.NbCouverts.toString(), START_X_COL2, startY, { align: "left" });

        doc.text("COUVERT MOYEN", pageWidth - 36, startY, "left");
        doc.text(" : " + (exportTo.data.MontantTTC / exportTo.data.NbCouverts).toFixed(2), pageWidth - 14, startY, { align: "left" });
    }
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(7);
    if (exportTo.data.Horodatage) {
        moment.locale("fr");
        startY += 5;

        doc.text(moment(exportTo.data.Horodatage).utc().format(
            "dddd DD MMMM YYYY HH:mm:ss"
        ), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    if (exportTo.data.NumTicket) {
        startY += 5;
        doc.text("TICKET : " + exportTo.data.NumTicket.toString(), START_TEXT_POS_X_CENTER, startY, { align: "center" });
    }

    addNewHeight()

    startY += 5
    let finalY = startY + 6

    // this for transfert
    if (exportTo['transfertArticles']?.length > 0) {

        /**title */
        startY += 5
        addDashedLine(startY, pageWidth)

        startY += 5;
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(9);
        doc.text(
            "TOTAL TRANSFERE : " + (exportTo['totalTransferts']).toFixed(2) + " €",
            START_TEXT_POS_X_CENTER, startY, { align: "center" }
        );
        startY += 5
        addDashedLine(startY, pageWidth)
        /**end title */

        finalY = startY + 2;
        var element = [];
        exportTo['transfertArticles']?.map((article) => {
            var Libelle = ''
            var TotalNet = ''
            var UnitaireTTC = ''
            var NetHT = ''
            //el?.ARTICLE?.map((article) => {
            if (article?.OFFERT) {
                Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                    UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
            } else {
                Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
            }
            element.push([
                Libelle,
                UnitaireTTC,
                NetHT + " €",
                TotalNet + " €"
            ]);
            //  });
            const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
            const tableWidth = pageWidth - 6; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            /////console.log(columnCount)
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                columnStyles[i].halign = 'left';

                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };
            console.log(columnStyles)
            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                //  margin: { left: 0 },
                headStyles: {
                    fontSize: 6
                }
            };
            doc.autoTable({
                margin: { top: 10, left: 3, right: 0 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element, // [el.GUID], //.slice(1),
                ...options, // Spread the options object
            });
        });
    }
    addNewHeight()
    /**tva transfert */
    /*** THIS FOR TVA */
    if (Object.keys(exportTo['TransfertTVA']).length > 0) {
        finalY = doc.autoTable.previous.finalY + 2
        var element = [];
        Object.entries(exportTo['TransfertTVA']).forEach(([code, el]) => {

            element.push([
                el?.Libelle,
                Number(el?.ValeurTVA).toFixed(2).toString() + " €",
                Number(el?.ValeurHT).toFixed(2).toString() + " €",
                Number(el?.ValeurTTC).toFixed(2).toString() + " €"
            ]);

            const cols = ["CODE", "TVA", "HT", "TTC"]
            const tableWidth = pageWidth - 6; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            /////console.log(columnCount)
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)

            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };

            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                //  margin: { left: 0 },
                headStyles: {
                    fontSize: 6
                }

            };
            doc.autoTable({
                margin: { top: 5, left: 3, right: 0 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element,
                ...options, // Spread the options object
            });
        });

        finalY = doc.autoTable.previous.finalY + 6
        startY = finalY;
        // doc.setDrawColor(0, 0, 0);
        // doc.line(0, startY, pageWidth, startY);
        finalY = finalY + 6
    }
    addNewHeight()
    if (exportTo['commandes']?.length > 0) {
        /** title */
        // Draw the line

        addDashedLine(startY, pageWidth)

        startY += 5;
        doc.setFont("Courier", "normal", "bold");
        doc.setFontSize(9);
        doc.text(
            "TOTAL TTC : " + (exportTo.data.MontantTTC).toFixed(2) + " €",
            START_TEXT_POS_X_CENTER, startY, { align: "center" }
        );
        startY += 5
        addDashedLine(startY, pageWidth)
        /**end  title */
        finalY = finalY + 6
        var element = [];
        exportTo['commandes']?.map((el) => {
            var Libelle = ''
            var TotalNet = ''
            var UnitaireTTC = ''
            var NetHT = ''
            el?.ARTICLE?.map((article) => {
                if (article?.OFFERT) {
                    Libelle = Number(article?.OFFERT?.Quantite).toFixed(0) + ' ' + article.Libelle
                    TotalNet = article?.OFFERT?.Libelle, // Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                        UnitaireTTC = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                    NetHT = Number(article?.OFFERT?.Valeur).toFixed(2).toString()
                } else {
                    Libelle = Number(article?.QuantiteNet).toFixed(0) + ' ' + article.Libelle
                    TotalNet = Number(article?.PrixTotalNet).toFixed(2).toString()
                    UnitaireTTC = Number(article?.PrixUnitaireTTC).toFixed(2).toString()
                    NetHT = Number(article?.PrixTotalNetHT).toFixed(2).toString()
                }
                element.push([
                    Libelle,
                    UnitaireTTC + " €",
                    NetHT + " €",
                    TotalNet + " €"
                ]);
            });
            const cols = ["DESIGNATION", "PU", "HT", "TOTAL "]
            const tableWidth = pageWidth - 6; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            /////console.log(columnCount)
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                columnStyles[i].halign = 'left';

                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };
            console.log(columnStyles)
            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                //margin: { left: 0 },
                headStyles: {
                    fontSize: 6
                }
            };
            doc.autoTable({
                margin: { top: 10, left: 3, right: 0 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element, // [el.GUID], //.slice(1),
                ...options, // Spread the options object
            });
        });
    }

    addNewHeight()
    finalY = doc.autoTable.previous.finalY + 6
    startY = finalY;
    startX1 = pageWidth - 40;
    startX2 = pageWidth - 2;
    addDashedLine(startY, pageWidth)

    // if (exportTo.data.LigneCompte) {
    //     startY += 9;
    //     doc.setFont("Courier", "normal", "bold");
    //     doc.setFontSize(8);
    //     doc.text("NOMBRE LIGNES ARTICLES", START_X_COL1, startY, "left");
    //     doc.text(" : " + exportTo.data.LigneCompte.toString(), START_X_COL2 + 24, startY, { align: "left" });
    // }


    addNewHeight()
    /*** THIS FOR TVA */
    if (exportTo?.data?.TVA) {
        finalY = startY + 2;
        const TVA = (Array.isArray(exportTo?.data?.TVA)) ? exportTo.data.TVA : [exportTo.data.TVA]
        var element = [];
        TVA?.map((el) => {
            // element[el.GUID] = [];
            // el.ARTICLE.map((article) => {
            element.push([
                el?.Libelle,
                Number(el?.ValeurTVA).toFixed(2).toString() + " €",
                Number(el?.ValeurHT).toFixed(2).toString() + " €",
                Number(el?.ValeurTTC).toFixed(2).toString() + " €"
            ]);
            //});
            console.log("element")
            console.log(element[0])

            const cols = ["CODE", "TVA", "HT", "TTC"]
            const tableWidth = pageWidth - 6; // Total width of the table
            const columnCount = cols.length; // Assuming all rows have the same number of columns
            /////console.log(columnCount)
            const columnWidth = tableWidth / columnCount;
            const FirstcellWidth = columnWidth + 15
            const OthercellWidth = (tableWidth - FirstcellWidth) / (columnCount - 1)
            // Set the starting position of the table
            /////console.log(columnWidth)
            // Set the starting position of the table
            const startXX = 20;
            let currentX = startXX;

            // Calculate the column widths and adjust the X-coordinate of each cell
            const columnStyles = {};
            for (let i = 0; i < columnCount; i++) {
                const cellWidth = (i === 0) ? FirstcellWidth : OthercellWidth;
                columnStyles[i] = { cellWidth: cellWidth };
                columnStyles[i].cellX = currentX;
                currentX += cellWidth;
            }
            const styles = {
                fontSize: 7, // Font size for the table body
                cellWidth: 'auto', // Allow the column width to adjust automatically
                overflow: 'linebreak', // Enable text wrapping within cells
            };

            const options = {
                startY: finalY, // Adjust the vertical position of the table
                columnStyles: columnStyles, // Set column widths
                styles: styles, // Set font sizes
                overflow: 'linebreak', // Enable automatic table resizing with text wrapping
                //margin: { left: 0 },
                headStyles: {
                    fontSize: 6
                }

            };
            doc.autoTable({
                margin: { top: 5, left: 3, right: 0 },
                pageBreak: "auto",
                theme: "plain",
                headStyles: {
                    fillColor: [29, 30, 34],
                    textColor: [255, 255, 255],
                    cellPadding: 3,
                },
                footStyles: { fillColor: [102, 102, 102], textColor: [255, 255, 255], cellPadding: 2 },
                bodyStyles: { cellPadding: 1 },
                head: [cols],
                body: element,
                ...options, // Spread the options object
            });
        });

        finalY = doc.autoTable.previous.finalY + 6
        startY = finalY;
        startX1 = 0;
        startX2 = pageWidth - 2;
        // Draw the line
        //startY +=5

        // doc.setFont("Courier", "normal", "normal");
        // doc.setFontSize(7);
        // doc.text("TOTAL", startX1 + 3, startY - 2, "left");
        // doc.text(" : " + (exportTo.data.MontantTTC).toFixed(2).toString(), startX2, startY - 2, "right");

    }

    addDashedLine(startY, pageWidth)
    doc.setFont("Courier", "normal", "bold");
    doc.setFontSize(10);
    startY += 5
    doc.text("TOTAL : ", START_X_COL1, startY, "left");
    doc.text((exportTo.data.MontantTTC).toFixed(2) + " €", pageWidth - 3,
        startY,
        "right");
    startY += 5

    addDashedLine(startY, pageWidth)

    addNewHeight()
    doc.setFont("Courier", "normal", "normal");
    doc.setFontSize(7);
    if (exportTo.data.LibCaisse) {
        startY += 8;

        doc.text("CAISSE", START_X_COL1, startY, "left");

        doc.text(" : " + exportTo.data.LibCaisse.toString(), START_X_COL2, startY, { align: "left" });
    }
    if (exportTo.data.Horodatage) {
        doc.text(moment(exportTo.data.Horodatage).utc().format(
            "DD/MM/YYYY HH:mm:ss"
        ),
            pageWidth - 3,
            startY,
            "right"
        );
    }

    if (exportTo.data.LibVendeur) {
        startY += 5;
        doc.text("VENDEUR", START_X_COL1, startY, "left");
        doc.text(" : " + exportTo.data.LibVendeur.toString(), START_X_COL2, startY, { align: "left" });
    }


    if (exportTo.data.Operation) {
        startY += 5;
        doc.text("Operation", START_X_COL1, startY, "left");
        doc.text(" : " + exportTo.data.Operation.toString(), START_X_COL2, startY, { align: "left" });
    }

    /* if (exportTo.data.VersionCaisse) {
         startY += 5;
 
         doc.text("VERSION", START_X_COL1, startY, "left");
 
         doc.text(" : " + exportTo.data.VersionCaisse.toString(), START_X_COL2, startY, { align: "left" });
     }
 
     if (exportTo.data.VersionCaisse) {
         startY += 5;
 
         doc.text("Nb Réimpression", START_X_COL1, startY, "left");
 
         doc.text(" : " + "0", START_X_COL2 + 8, startY, { align: "left" });
     }*/
    console.log("sendtomail : " + sendtomail)

    addNewHeight()


    if (sendtomail) {
        // Generate the base64-encoded representation of the PDF
        const base64PDF = doc.output('dataurlstring');

        // Convert base64 to Buffer
        const pdfBuffer = base64PDF.split('base64,')[1]


        console.log('PDF Buffer:', pdfBuffer);
        return pdfBuffer
    } else {
        doc.save(exportTo.fileName);
    }
}

