<!-- eslint-disable -->
<template>
  <div @click="generatePdf" class="w-100 text-center generate-to-file pdf size-15">
    <i class="far fa-file-pdf"></i> pdf
  </div>
</template>
<!-- eslint-disable -->

<script>
//import Vue3Html2pdf from "vue3-html2pdf";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { Division, alertSwal } from "@/Utils/globale.js";
import { exportToExcel, exportToPdfMail } from "@/Utils/generatedFile.js";
import { exportMultiTicketsToPdf } from "@/Utils/generatedTickets.js";

export default {
  props: ["exportTo", "type"],
  components: {
    // Vue3Html2pdf,
  },
  data() {
    return {
      clicked: false,
    };
  },
  methods: {
    async generatePdf() {
      exportMultiTicketsToPdf(this.exportTo, this.type)
    },
  },
  mounted() {

  },
};
</script>
